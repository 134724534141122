/**
 * File: SelectedBill.js
 * Description: This file defines the `SelectedBill` component which displays a modal popup to display the current bill details along with
 *              the total bill amount.
 * Author: Nethmi Aththanayake
 * Created: 14/2/2023
 * Last Modified: 14/5/2024 Sulakshanee Theja
 */

import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../../styles/billingStyles.scss';
import { MONTH_ARRAY } from '../../../constants';

const SelectedBill = ({ click, setClick, selectedBill, selectedDeviceCurrencySymbol }) => {
  return (
    <div>
      <Modal show={click} className="current-bill-modal">
        <Modal.Header className="current-bill-modal-header">
          {selectedBill.device_name} Bill For {selectedBill.year} -{' '}
          {MONTH_ARRAY[selectedBill.month - 1]}
        </Modal.Header>

        <Modal.Body>
          <div className="m-0 row">
            <div className="m-0 col-8 current-bill-body-detail">
              {selectedBill.desc1 && <p>{selectedBill.desc1}</p>}
              {selectedBill.desc2 && <p>{selectedBill.desc2}</p>}
              {selectedBill.desc3 && <p>{selectedBill.desc3}</p>}
              {selectedBill.desc4 && <p>{selectedBill.desc4}</p>}
              {selectedBill.desc5 && <p>{selectedBill.desc5}</p>}
              {selectedBill.desc6 && <p>{selectedBill.desc6}</p>}
              {selectedBill.desc7 && <p>{selectedBill.desc7}</p>}
              {selectedBill.desc8 && <p>{selectedBill.desc8}</p>}
              {selectedBill.desc9 && <p>{selectedBill.desc9}</p>}
              {selectedBill.desc10 && <p>{selectedBill.desc10}</p>}
            </div>

            <div className="m-0 col-4 current-bill-body-amount">
              {selectedBill.amount1 && (
                <p>
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.amount1).toFixed(2)}
                </p>
              )}
              {selectedBill.amount2 && (
                <p>
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.amount2).toFixed(2)}
                </p>
              )}
              {selectedBill.amount3 && (
                <p>
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.amount3).toFixed(2)}
                </p>
              )}
              {selectedBill.amount4 && (
                <p>
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.amount4).toFixed(2)}
                </p>
              )}
              {selectedBill.amount5 && (
                <p>
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.amount5).toFixed(2)}
                </p>
              )}
              {selectedBill.amount6 && (
                <p>
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.amount6).toFixed(2)}
                </p>
              )}
              {selectedBill.amount7 && (
                <p>
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.amount7).toFixed(2)}
                </p>
              )}
              {selectedBill.amount8 && (
                <p>
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.amount8).toFixed(2)}
                </p>
              )}
              {selectedBill.amount9 && (
                <p>
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.amount9).toFixed(2)}
                </p>
              )}
              {selectedBill.amount10 && (
                <p>
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.amount10).toFixed(2)}
                </p>
              )}
            </div>
          </div>

          <hr />

          <div className="m-0 row">
            <div className="m-0 col-8 current-bill-body-total">
              <p>Total</p>
            </div>

            <div className="m-0 col-4 current-bill-body-amount">
              <p>
                {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.net_total).toFixed(2)}
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            onClick={() => setClick(false)}
            className="current-bill-close-button"
            data-dismiss="modal">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SelectedBill;
