/**
 * File: SideBar.js
 * Description: This file defines the sidebar panel component that displays navigation options.
 * Author: Nethmi Aththanayaka
 * Created: 22/8/2023
 * Last Modified: 26/3/2024 Sulakshanee Theja
 */

import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { logout } from '../../api';
import ParaqumTransparentLogo from '../../assets/logos/paraqumTransparentLogo.png';
import '../../styles/sidebar.scss';
import { useAuth } from '../../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { HTTP_STATUS_OK } from '../../constants/httpStatusCodeConstants';
import {
  CloseNavIcon,
  DropdownLink,
  Nav,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarLabel,
  SidebarLink,
  SidebarNav,
  SidebarWrap,
  ToggledNavIcon,
} from './StyledSideBar';

const SideBar = ({ sidebar, setSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLocalStorageToken } = useAuth();

  const [subnav, setSubnav] = useState(false);

  let arr = location.pathname.toString().split('/');
  let currentPath = arr[1];

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const handleLogout = () => {
    logout()
      .then((res) => {
        if (res.data.status === HTTP_STATUS_OK) {
          localStorage.clear();
          setLocalStorageToken(null);
          navigate('/');
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const showSubnav = () => setSubnav(!subnav);

  const hideSubnav = () => setSubnav(false);

  return (
    <div className="col-md-2">
      <Nav>
        <ToggledNavIcon to="#">
          <Icon.List onClick={showSidebar} />
        </ToggledNavIcon>
      </Nav>

      <SidebarNav sidebar={sidebar}>
        <SidebarWrap>
          <CloseNavIcon to="#">
            <Icon.XSquare onClick={showSidebar} />
          </CloseNavIcon>

          <SidebarHeader>
            <img src={ParaqumTransparentLogo} alt="Logo" className="sidebar-logo" />
          </SidebarHeader>

          <SidebarContent>
            <SidebarLink
              to={'/dashboard'}
              onClick={() => {
                hideSubnav();
                showSidebar();
              }}
              position={currentPath}
              title={'Dashboard'}>
              <div>
                <Icon.GraphUpArrow />
                <SidebarLabel>{'Dashboard'}</SidebarLabel>
              </div>
            </SidebarLink>

            <SidebarLink to={'#'} onClick={showSubnav} position={currentPath} title={'Products'}>
              <div>
                <Icon.HddNetwork />
                <SidebarLabel>{'Products'}</SidebarLabel>
              </div>
              <div>
                {subnav ? <Icon.ChevronUp /> : subnav == false ? <Icon.ChevronDown /> : null}
              </div>
            </SidebarLink>

            <div>
              {subnav ? (
                <>
                  <DropdownLink
                    to={'/products'}
                    onClick={showSidebar}
                    position={currentPath}
                    title={'Overview'}>
                    <Icon.ListUl />
                    <SidebarLabel>{'Overview'}</SidebarLabel>
                  </DropdownLink>
                </>
              ) : null}
            </div>

            <SidebarLink
              to={'/billing'}
              onClick={() => {
                hideSubnav();
                showSidebar();
              }}
              position={currentPath}
              title={'Billing'}>
              <div>
                <Icon.GraphUpArrow />
                <SidebarLabel>{'Billing'}</SidebarLabel>
              </div>
            </SidebarLink>

            <SidebarLink
              to={'/ticket'}
              onClick={() => {
                hideSubnav();
                showSidebar();
              }}
              position={currentPath}
              title={'Ticket'}>
              <div>
                <Icon.ExclamationCircle />
                <SidebarLabel>{'Ticket'}</SidebarLabel>
              </div>
            </SidebarLink>
          </SidebarContent>

          <SidebarFooter
            to={null}
            position={currentPath}
            onClick={() => {
              handleLogout();
            }}>
            <div>
              <Icon.Power size={24} />
              <SidebarLabel>Log Out</SidebarLabel>
            </div>
          </SidebarFooter>
        </SidebarWrap>
      </SidebarNav>
    </div>
  );
};

export default SideBar;
