/**
 * File: CheckResetToken.js
 * Description: This file contains the CheckResetToken component responsible for verifying the validity of a password reset URL and
 *              allowing users to submit a reset code.
 * Author: Sulakshanee Theja
 * Created: 15/8/2024
 * Last Modified: 21/8/2024 Sulakshanee Theja
 */

import React, { useState, useEffect } from 'react';
import ParaqumLogo from '../../assets/logos/paraqumLogo.jpg';
import '../../styles/auth.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { checkResetToken, verifyResetPasswordURL } from '../../api';
import Swal from 'sweetalert2';
import { HTTP_STATUS_OK } from '../../constants/httpStatusCodeConstants';

const CheckResetToken = () => {
  const navigate = useNavigate();
  const params = useParams();
  const clientId = params.uuid;

  const [isValidURL, setIsValidURL] = useState(false);
  const [code, setCode] = useState();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const verifyURL = async () => {
      const body = {
        uuid: clientId,
      };

      verifyResetPasswordURL(body)
        .then((res) => {
          if (res.status === HTTP_STATUS_OK) {
            setIsValidURL(true);
            return;
          } else {
            setIsValidURL(false);
            navigate('/reset-password-error');
          }
        })
        .catch(() => {
          setIsValidURL(false);
          navigate('/reset-password-error');
        });
    };

    verifyURL();
  }, [clientId, navigate]);

  const validateResetCode = () => {
    let errors = {};

    if (!code) {
      errors.code = 'Password reset code required';
    }

    return errors;
  };

  const checkResetCode = (e) => {
    e.preventDefault();
    const isValid = validateResetCode();

    if (isValid.code) {
      setErrors(isValid);
    } else {
      setErrors('');

      const body = {
        client: clientId,
        token: code,
      };

      checkResetToken(body)
        .then((res) => {
          if (!res.data.error) {
            Swal.fire({
              icon: 'success',
              text: 'Code Verified Successfully !',
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
            navigate('/reset-Password', {
              state: {
                clientId: clientId,
                code: code,
              },
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            text: err.response.data.message,
            timer: 3000,
            confirmButtonColor: '#024b77',
          });
        });
    }
  };

  return (
    <>
      {isValidURL === true ? (
        <div className="p-0 m-0 row container-fluid auth-main-container">
          <div className="p-0 m-0 col-md-6 logo-container">
            <img src={ParaqumLogo} alt="Paraqum Logo" className="logo-image" />
          </div>

          <div className="p-0 m-0 col-md-6 auth-form-container">
            <div className="auth-form-sub-container">
              <div className="header-text">Reset Password</div>

              <div className="auth-form">
                <div className="auth-form-field-container">
                  <label className="auth-form-label" htmlFor="code">
                    Code
                  </label>
                  <div className="auth-form-field">
                    <input
                      type="code"
                      className="auth-field-input"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                        setErrors({ ...errors, code: '' });
                      }}
                    />
                  </div>
                  {errors.code && <p className="auth-field-error">{errors.code}</p>}
                </div>

                <button className="auth-form-button" onClick={checkResetCode}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CheckResetToken;
