import React, { useEffect, useState } from 'react';
import api from '../../axiosContact';
import moment from 'moment';

function HistoryComponent({ product, defaultProduct }) {

    const [billingDetails, setBillingDetails] = useState([]);

    useEffect(() => {

        let header = {
            headers: {
                'access-token': localStorage.getItem('token'),
            }
        };

        const fetchData = async () => {
            try {
                if (product) {
                    const licenses = await api.get(`/clients/licensesByProductId/${product}`, header);
                    setBillingDetails(licenses.data.data);
                } else {
                    const licenses = await api.get(`/clients/licensesByProductId/${defaultProduct}`, header);
                    setBillingDetails(licenses.data.data);
                }

            } catch (error) {
                console.log(error);
            }

        }

        fetchData();

    }, [defaultProduct, product])

    return (
        <div>
            <div className="table-responsive">
                {(billingDetails.length !== 0) ?
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col" className="historycomponent-tr-heading">License Name</th>
                                <th scope="col" className="historycomponent-tr-heading">Effective Date</th>
                                <th scope="col" className="historycomponent-tr-heading">Expiry Date</th>
                                <th scope="col" className="historycomponent-tr-heading">Paid On</th>
                                <th scope="col" className="historycomponent-tr-heading">Amount ($)</th>
                                <th scope="col" className="historycomponent-tr-heading">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {billingDetails.map((data, key) => (
                                <tr id="trow" className="align-middle" key={key}>
                                    <td className="historycomponent-tr-data">{data.name}</td>
                                    <td className="historycomponent-tr-data">{moment(data.effective_date).format('MMM Do YY')}</td>
                                    <td className="historycomponent-tr-data">{moment(data.expiry_date).format('MMM Do YY')}</td>
                                    <td className="historycomponent-tr-data">{moment(data.requested_on).format('MMM Do YY')}</td>
                                    <td className="historycomponent-tr-data">{data.amount}</td>
                                    <td className="historycomponent-tr-data">{
                                        data.status === 'active' ? <span id="active-status">Active</span>
                                            : data.status === 'issued' ? <span id="download-status" style={{ textDecoration: 'none' }}>Issued</span>
                                                : <span id="pending-status">Pending</span>
                                    }</td>
                                </tr>
                            ))}


                        </tbody>
                    </table>
                    :
                    <p className="fs-6">This product has no billing history to show.</p>
                }
            </div>
        </div>
    )
}

export default HistoryComponent;
