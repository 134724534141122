/**
 * File: authConstants.js
 * Description: Login page related constants.
 * Author: Sulakshanee Thjea
 * Created: 13/3/2024
 * Last Modified: 13/3/2024 Sulakshanee Thjea
 */

// Password type
export const PASSWORD_TYPE = {
  PASSWORD: 'password',
  TEXT: 'text',
};
