/**
 * File: index.js(src)
 * Description: This is the main entry point of the React application. It renders the App component
 *              wrapped in authentication and user context providers.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 26/3/2024 Sulakshanee Theja
 */

import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { AuthProvider } from './contexts/AuthContext';
import App from './App';

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <AuthProvider>
    <App />
  </AuthProvider>,
  // </React.StrictMode>,
);
