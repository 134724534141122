/**
 * File: DashboardLicenses.js
 * Description: This file contains license expiration details and announcements.
 * Author: Sulakshanee Theja
 * Created: 27/6/2024
 * Last Modified: 23/7/2024 Sulakshanee Theja
 */
import React, { useState } from 'react';
import '../../../styles/dashboard.scss';
import LicenseCarousel from '../../../components/dashboard/licenseCarousel';

function DashboardLicenses() {
  const [announcements] = useState([]);

  return (
    <div className="license-container">
      <p className="dashboard-sub-component-title">Licenses</p>

      <LicenseCarousel />

      <p className="dashboard-sub-component-title">Announcements</p>
      <hr className="horizontal-line"></hr>

      <div tabIndex="0">
        {announcements?.length > 0 ? (
          announcements.map((announcement, key) => (
            <div key={key}>
              <div className="announcement-container">
                <div>
                  <p className="announcement-title">{announcement?.title}</p>
                  <p className="announcement-date">{announcement?.date}</p>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-box-arrow-up-right"
                  viewBox="0 0 16 16"
                  color="#0D2841">
                  <path
                    fillRule="evenodd"
                    d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                  />
                </svg>
              </div>
            </div>
          ))
        ) : (
          <div className="no-announcement-container">No announcements to show at the moment.</div>
        )}
      </div>
    </div>
  );
}

export default DashboardLicenses;
