/**
 * File: DashboardOverview.js
 * Description: This file contains count of Products, Tickets and Devices.
 * Author: Sulakshanee Theja
 * Created: 19/9/2023
 * Last Modified: 27/6/2024 Sulakshanee Theja
 */

import React, { useState, useEffect } from 'react';
import { getTicketsByClientId } from '../../../api';
import { Link } from 'react-router-dom';
import '../../../styles/dashboard.scss';
import { TICKET_STATUS } from '../../../constants/ticketConstants';

function DashboardOverview({ deviceList }) {
  const [productCount, setProductCount] = useState([]);
  const [deviceCount, setDeviceCount] = useState([]);
  const [newTicketCount, setNewTicketCount] = useState([]);

  useEffect(() => {
    let productList = [];

    for (let i = 0; i < deviceList.length; i++) {
      if (i === 0) {
        productList.push(deviceList[i].product);
      } else {
        let foundSameProduct = false;

        for (let j = 0; j < productList.length; j++) {
          if (deviceList[i].product === productList[j]) {
            foundSameProduct = true;
          }
        }

        if (!foundSameProduct) {
          productList.push(deviceList[i].product);
        }
      }
    }
    setProductCount(productList.length);
    setDeviceCount(deviceList.length);

    getTicketsByClientId()
      .then((res) => {
        if (!res.data.error) {
          const newTicketList = res.data.data.filter((ticket) => {
            return ticket.status === TICKET_STATUS.NEW_TICKET;
          });
          const newTicketLength = newTicketList.length;
          setNewTicketCount(newTicketLength);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [deviceList]);

  return (
    <div className="overview-container">
      <p className="dashboard-sub-component-title">Overview</p>

      <div className="row m-0 p-0">
        <Link to="/products" className="col-md-4 overview-item-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="50"
            height="50"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
            color="#0AAEEF"
            opacity="0.7">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            />
          </svg>

          <div className="overview-item-detail-container">
            {productCount < 10 ? (
              <p className="overview-item-number">0{productCount}</p>
            ) : (
              <p className="overview-item-number">{productCount}</p>
            )}
            <div className="overview-item-text-container">
              {productCount > 1 ? (
                <p className="m-0 p-0 overview-detail-main-text">PRODUCTS</p>
              ) : (
                <p className="m-0 p-0 overview-detail-main-text">PRODUCT</p>
              )}
              <p className="m-0 p-0 overview-detail-sub-text">In total</p>
            </div>
          </div>
        </Link>

        <Link to="/products" className="col-md-4 overview-item-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            className="bi bi-hdd-network"
            viewBox="0 0 16 16"
            color="#0AAEEF"
            opacity="0.7">
            <path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2V4zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1zm6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5z" />
          </svg>

          <div className="overview-item-detail-container">
            {deviceCount < 10 ? (
              <p className="overview-item-number">0{deviceCount}</p>
            ) : (
              <p className="overview-item-number">{deviceCount}</p>
            )}
            <div className="overview-item-text-container">
              {deviceCount > 1 ? (
                <p className="m-0 p-0 overview-detail-main-text">DEVICES</p>
              ) : (
                <p className="m-0 p-0 overview-detail-main-text">DEVICE</p>
              )}
              <p className="m-0 p-0 overview-detail-sub-text">Created</p>
            </div>
          </div>
        </Link>

        <Link to="/ticket" className="col-md-4 overview-item-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            className="bi bi-exclamation-circle"
            viewBox="0 0 16 16"
            color="#0AAEEF"
            opacity="0.7">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
          </svg>

          <div className="overview-item-detail-container">
            {newTicketCount < 10 ? (
              <p className="overview-item-number">0{newTicketCount}</p>
            ) : (
              <p className="overview-item-number">{newTicketCount}</p>
            )}
            <div className="overview-item-text-container">
              {newTicketCount > 1 ? (
                <p className="m-0 p-0 overview-detail-main-text">TICKETS</p>
              ) : (
                <p className="m-0 p-0 overview-detail-main-text">TICKET</p>
              )}
              <p className="m-0 p-0 overview-detail-sub-text">New</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default DashboardOverview;
