/**
 * File: TicketDetails.js
 * Description: This file contains a component to display the ticket details for the selected ticket.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 7/10/2024 Sulakshanee Theja
 */

import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import '../../../../styles/clientModal.scss';
import { Modal } from 'react-bootstrap';
import CommentSection from '../ticketComments/CommentSection';
import Swal from 'sweetalert2';
import { addComment, downloadTicketAttachment } from '../../../../api';
import {
  TICKET_GENERIC_TYPE,
  TICKET_PRIORITY,
  TICKET_STATUS,
  TICKET_TYPE,
} from '../../../../constants/ticketConstants';
import TicketStatus from './TicketStatus';
import CircularProgress from '@mui/material/CircularProgress';
import { reopenTicket } from '../../../../api';

const TicketDetails = ({
  ticketId,
  ticket,
  deviceList,
  clientDetails,
  ticketComments,
  fetchTicketComments,
  fetchOtherData,
  fetchTicketsData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(-1);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [addCommentErrors, setAddCommentErrors] = useState([]);

  const device =
    ticket?.device && deviceList && deviceList.filter((device) => device.uuid === ticket.device)[0];

  const addCommentModalOpen = () => {
    setIsModalOpen((pre) => !pre);
  };

  const addCommentModalClose = () => {
    setIsModalOpen((pre) => !pre);
  };

  const validateNewComment = () => {
    let errors = {};

    if (!description) {
      errors.description = 'Description is required';
    }

    return errors;
  };

  const addNewComment = async (e) => {
    e.preventDefault();
    const isValid = validateNewComment();

    if (isValid.description) {
      setAddCommentErrors(isValid);
    } else {
      setIsButtonDisabled(true);

      const data = new FormData();

      [...files].forEach((file) => {
        data.append('attachments', file);
      });

      data.append('ticket_id', ticketId.toString());
      data.append('datasource', 'portal');
      data.append('content', description);
      data.append('created_by_paraqum', 0);

      try {
        const res = await addComment(data);

        if (!res.data.error) {
          setAddCommentErrors({});
          Swal.fire({
            icon: 'success',
            text: 'Comment Added Successfully !',
            timer: 5000,
            confirmButtonColor: '#024b77',
          });
          setDescription('');
          setFiles([]);
          fetchTicketComments(ticketId);
          addCommentModalClose();
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Failed to Add Comment !',
            timer: 5000,
            confirmButtonColor: '#024b77',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'Failed to Add Comment !',
          timer: 5000,
          confirmButtonColor: '#024b77',
        });
      } finally {
        setIsButtonDisabled(false);
      }
    }
  };

  const handleFileChange = (e) => {
    setFiles(e.target.files);
    setAddCommentErrors({ ...addCommentErrors, files: '' });
  };

  // Download attachment from api response
  const downloadAttachment = async (attachment, index) => {
    setLoading((pre) => !pre);
    setSelectedAttachment(index);

    downloadTicketAttachment(attachment.token)
      .then((res) => {
        setLoading((pre) => !pre);
        setSelectedAttachment(-1);
        const downloadResponse = res.data;

        const attachmentType = downloadResponse.type.split('/')[1];

        const url = window.URL.createObjectURL(new Blob([downloadResponse]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `attachment.${attachmentType}`);

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        setLoading((pre) => !pre);
        setSelectedAttachment(-1);
        console.log(error);
      });
  };

  // Reopen Ticket
  const reopenClosedTicket = (id) => {
    const body = {
      id: id,
    };

    Swal.fire({
      icon: 'question',
      text: 'Are you sure you want to reopen this Ticket?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      confirmButtonColor: '#024b77',
    }).then(async (result) => {
      if (result.isConfirmed) {
        reopenTicket(body)
          .then((res) => {
            if (!res.data.error) {
              Swal.fire({
                icon: 'success',
                text: 'Ticket Reopened Successfully !',
                timer: 5000,
                confirmButtonColor: '#024b77',
              });
              fetchTicketsData();
              fetchOtherData();
            }
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              text: 'Failed to Reopen Ticket!',
              timer: 5000,
              confirmButtonColor: '#024b77',
            });
          });
      }
    });
  };

  return (
    <>
      <Modal show={isModalOpen} className="client-modal-main">
        <Modal.Header className="client-modal-header">
          <h5 className="modal-title" id="addCommentModal">
            Add Comment
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              addCommentModalClose();
            }}></button>
        </Modal.Header>

        <Modal.Body>
          <div className="modal-body ps-4 pe-4">
            <div className="row client-modal-field-container">
              <div className="col-md-4 client-modal-field-label">Description</div>
              <div className="col-md-8">
                <textarea
                  className="client-modal-field"
                  style={{
                    borderColor: addCommentErrors.description ? '#D00000' : '#094d76',
                    height: '100px',
                  }}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setAddCommentErrors({
                      ...addCommentErrors,
                      description: '',
                    });
                  }}
                />
              </div>
            </div>

            <div className="row client-modal-field-container">
              <div className="col-md-4 client-modal-field-label">Attachments</div>
              <div className="col-md-8">
                <input
                  className="client-modal-field"
                  type="file"
                  accept="image/*, .pdf, .zip "
                  multiple
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="client-modal-footer">
          <button
            type="button"
            style={{ background: '#B3B9BF' }}
            data-bs-dismiss="modal"
            onClick={() => {
              addCommentModalClose();
            }}>
            Close
          </button>

          <button
            type="button"
            style={{ background: '#0472CC', color: 'white' }}
            onClick={addNewComment}
            disabled={isButtonDisabled}>
            Send
          </button>
        </Modal.Footer>
      </Modal>

      <div className="ticket-details-sub-container">
        <div className="ticket-details-title-container">
          <div className="row">
            <div className="col-md-8">
              <div className="ticket-details-title">{ticket.subject}</div>
              <div className="ticket-details-priority">
                Priority -{' '}
                <span
                  style={{
                    color:
                      ticket.priority === TICKET_PRIORITY.HIGH
                        ? '#EF5146'
                        : ticket.priority === TICKET_PRIORITY.MEDIUM
                        ? '#0B9919'
                        : '#FBC21D',
                  }}>
                  {ticket.priority === TICKET_PRIORITY.LOW
                    ? 'Low'
                    : ticket.priority === TICKET_PRIORITY.MEDIUM
                    ? 'Medium'
                    : 'High'}
                </span>
              </div>
            </div>

            <div className="col-md-4 button-container">
              {ticket.status !== TICKET_STATUS.CLOSED_TICKET ? (
                <button
                  type="button"
                  className="btn add_comment_or_reopen-button"
                  style={{ backgroundColor: '#0472CC' }}
                  data-bs-toggle="modal"
                  data-bs-target="#addCommentModal"
                  onClick={addCommentModalOpen}>
                  Add Comment
                </button>
              ) : (
                <button
                  type="button"
                  className="btn add_comment_or_reopen-button"
                  style={{ backgroundColor: '#6c757d' }}
                  onClick={() => {
                    reopenClosedTicket(ticket.id);
                  }}>
                  Reopen Ticket
                </button>
              )}
            </div>
          </div>

          <div className="ticket-details-description">{ticket.description}</div>
        </div>

        <div className="row ticket-details-body-container">
          <div className="p-0 col-md-7">
            <div className="row ticket-detail-item">
              <div className="col-md-6 label-container">
                <div className="label-sub-container">
                  <Icon.FileEarmarkFont color="#0aaeef" size={20} />
                  <span className="ticket-details-Label">Ticket No</span>
                </div>
                <span>:</span>
              </div>

              <div className="col-md-6 ticket-details">{ticket.id}</div>
            </div>

            <div className="row ticket-detail-item">
              <div className="col-md-6 label-container">
                <div className="label-sub-container">
                  <Icon.QuestionCircle color="#0aaeef" size={20} />
                  <span className="ticket-details-Label">Status</span>
                </div>
                <span>:</span>
              </div>

              <div className="col-md-6 ticket-details">
                {ticket.status === TICKET_STATUS.NEW_TICKET
                  ? 'New'
                  : ticket.status === TICKET_STATUS.IN_PROGRESS_TICKET
                  ? 'In Progress'
                  : 'Closed'}
              </div>
            </div>

            {ticket.attachments && ticket.attachments.length !== 0 ? (
              <div className="row ticket-detail-item">
                <div className="col-md-6 label-container">
                  <div className="label-sub-container">
                    <Icon.Paperclip color="#0aaeef" size={20} />
                    <span className="ticket-details-Label">Attachments</span>
                  </div>
                  <span>:</span>
                </div>

                <div className="col-md-6 ticket-details">
                  {ticket.attachments &&
                    ticket.attachments.map((attachment, index) => (
                      <button
                        key={index}
                        type="button"
                        className="btn btn-outline-secondary btn-sm me-2"
                        onClick={() => downloadAttachment(attachment, index)}>
                        {loading && selectedAttachment === index ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Icon.Paperclip color="#0aaeef" size={20} />
                        )}
                      </button>
                    ))}
                </div>
              </div>
            ) : null}

            {parseInt(ticket.type) === TICKET_TYPE.ON_A_PRODUCT_TICKET ? (
              <div className="row ticket-detail-item">
                <div className="col-md-6 label-container">
                  <div className="label-sub-container">
                    <Icon.UsbMicro color="#0aaeef" size={20} />
                    <span className="ticket-details-Label">Device Name</span>
                  </div>
                  <span>:</span>
                </div>

                <div className="col-md-6 ticket-details">{device?.device_name}</div>
              </div>
            ) : (
              <div className="row ticket-detail-item">
                <div className="col-md-6 label-container">
                  <div className="label-sub-container">
                    <Icon.DashCircle color="#0aaeef" size={20} />
                    <span className="ticket-details-Label">Generic Type</span>
                  </div>
                  <span>:</span>
                </div>

                <div className="col-md-6 ticket-details">
                  {parseInt(ticket.generic_type) === TICKET_GENERIC_TYPE.SOFTWARE_ISSUE
                    ? 'Software Issue'
                    : parseInt(ticket.generic_type) === TICKET_GENERIC_TYPE.HARDWARE_ISSUE
                    ? 'Hardware Issue'
                    : 'Other'}
                </div>
              </div>
            )}

            {ticket.status === TICKET_STATUS.CLOSED_TICKET ? (
              <div className="row ticket-detail-item">
                <div className="col-md-6 label-container">
                  <div className="label-sub-container">
                    <Icon.RCircle color="#0aaeef" size={20} />
                    <span className="ticket-details-Label">Closing Remark</span>
                  </div>
                  <span>:</span>
                </div>

                <div className="col-md-6 ticket-details">{ticket.closing_remarks}</div>
              </div>
            ) : null}
          </div>

          <TicketStatus ticketStatuses={ticket.statuses} />
        </div>
      </div>

      <CommentSection
        ticketComments={ticketComments}
        downloadAttachment={downloadAttachment}
        loading={loading}
        selectedAttachment={selectedAttachment}
        clientDetails={clientDetails}
      />
    </>
  );
};

export default TicketDetails;
