/**
 * File: localStorage.js
 * Description: This file serves as a centralized location for managing constant values
 *              representing keys used in the Local Storage. Storing all Local Storage keys
 *              as constants here helps prevent accidental key overrides, provides
 *              consistency, and enhances maintainability across the application.
 *
 *              When using Local Storage in the application, import these constants
 *              and use them to reference the keys, ensuring a standardized approach to
 *              managing Local Storage data.
 * Author: John Lesley
 * Created: 18/12/2023
 * Last Modified: 18/12/2023 John Lesley
 */

export const LOCAL_STORAGE_TOKEN = 'token';
