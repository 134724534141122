/**
 * File: Step1.js
 * Description: Select the ticket type to create tickets.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 5/2/2024 Sulakshanee Theja
 */

import React, { useCallback, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Swal from 'sweetalert2';
import { TICKET_TYPE } from '../../../../constants/ticketConstants';

const Step1 = ({ step, setStep, ticketType, setTicketType }) => {
  const [isHovering, setIsHovering] = useState('');

  const handleMouseEnter = (value) => {
    setIsHovering(value);
  };

  const handleMouseLeave = (value) => {
    setIsHovering(value);
  };

  // To go to the next step
  const nextStep = useCallback(() => {
    if (ticketType !== null) {
      setStep(step + 1);
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select a ticket type to proceed.',
        confirmButtonColor: '#024b77',
      });
    }
  }, [setStep, ticketType, step]);

  return (
    <>
      <div className="step-title">Specify the Type of Ticket</div>

      <div className="description">
        You can click the &ldquo;Generic Ticket&ldquo; if your concern is not product-oriented.
        Click the &ldquo;On a Product&ldquo; to raise any concern related to your Paraqum products.
      </div>

      <div className="row type-container">
        <div
          className="ticket-type"
          onClick={() => {
            setTicketType(TICKET_TYPE.GENERIC_TICKET);
            nextStep();
          }}
          style={{
            backgroundColor:
              ticketType === TICKET_TYPE.GENERIC_TICKET || isHovering === TICKET_TYPE.GENERIC_TICKET
                ? '#0aaeef1a'
                : '#FFFFFF',
          }}
          onMouseEnter={() => handleMouseEnter(TICKET_TYPE.GENERIC_TICKET)}
          onMouseLeave={() => handleMouseLeave('')}>
          <Icon.ExclamationCircle color="#666666" size={64} />
          <div>Generic Ticket</div>
        </div>

        <div
          className="ticket-type"
          onClick={() => {
            setTicketType(TICKET_TYPE.ON_A_PRODUCT_TICKET);
            nextStep();
          }}
          style={{
            backgroundColor:
              ticketType === TICKET_TYPE.ON_A_PRODUCT_TICKET ||
              isHovering === TICKET_TYPE.ON_A_PRODUCT_TICKET
                ? '#0aaeef1a'
                : '#FFFFFF',
          }}
          onMouseEnter={() => handleMouseEnter(TICKET_TYPE.ON_A_PRODUCT_TICKET)}
          onMouseLeave={() => handleMouseLeave('')}>
          <Icon.ExclamationSquare color="#666666" size={64} />
          <div>On a Product</div>
        </div>
      </div>
    </>
  );
};

export default Step1;
