/**
 * File: SupportDetails.js
 * Description: This file contains the user's supporting information like address, country, company name.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 23/7/2024 Sulakshanee Theja
 */

import React from 'react';
import { DEFAULT_STATE, DEFAULT_ZIP_CODE } from '../../../constants/clientConstants';

const SupportDetails = ({ company, contactPerson, client }) => {
  return (
    <div className="details-card-container">
      <div className="details-card-header details-card-header-text">Support Details</div>

      <div>
        <div className="row support-container">
          <div className="col-md-5 support-container-label">
            <div>Company Name</div>
            <span>:</span>
          </div>
          <div className="col-md-7 support-container-detail">{company}</div>
        </div>

        <div className="row support-container">
          <div className="col-md-5 support-container-label">
            <div>Contact Person</div>
            <span>:</span>
          </div>
          <div className="col-md-7 support-container-detail">{contactPerson}</div>
        </div>

        <div className="row support-container">
          <div className="col-md-5 support-container-label">
            <div>Address</div>
            <span>:</span>
          </div>
          <div className="col-md-7 support-container-detail">
            {client.addr_1}
            <br></br>
            {client.addr_2}
            <br></br>
            {client.addr_3}
            <br></br>
          </div>
        </div>

        <div className="row support-container">
          <div className="col-md-5 support-container-label">
            <div>State</div>
            <span>:</span>
          </div>
          <div className="col-md-7 support-container-detail">
            {client.state === DEFAULT_STATE ? '-' : client.state}
          </div>
        </div>

        <div className="row support-container">
          <div className="col-md-5 support-container-label">
            <div>Country</div>
            <span>:</span>
          </div>
          <div className="col-md-7 support-container-detail">{client.country}</div>
        </div>

        <div className="row support-container">
          <div className="col-md-5 support-container-label">
            <div>Zip Code</div>
            <span>:</span>
          </div>
          <div className="col-md-7 support-container-detail">
            {client.zip_code === DEFAULT_ZIP_CODE ? '-' : client.zip_code}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportDetails;
