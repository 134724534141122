/*
*
* File Name - BillingHistory.js
* Author(s) - Nethmi Aththanayake
* Created on - 4th January 2023, 9:54 AM
* Description - Display billing history of client 
*
*/

import React from 'react';
import '../../../styles/billingStyles.scss';

const BillingHistory = () => {
    return(
        <div className="container-fluid ">
            <div className="container billinghistory-container" >
                <div className="row m-0 p-0">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead id="borderless-th">
                                <tr>
                                    <th scope="col-sm-1" className="billing-tableheading-data">Payment Due Date</th>
                                    <th scope="col-sm-1" className="billing-tableheading-data">Subscriber Count</th>
                                    <th scope="col-sm-1" className="billing-tableheading-data">Amount</th>
                                    <th scope="col-sm-1" className="billing-tableheading-data">Payment Date</th>
                                    <th scope="col-sm-1" className="billing-tableheading-data">Status</th>
                                    <th scope="col-sm-1" className="billing-tableheading-data"> </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="trow" className="align-middle">
                                    <td className="billing-table-data">2021-Sept-21</td>
                                    <td className="billing-table-data">70</td>
                                    <td className="billing-table-data-download">$50</td>
                                    <td className="billing-table-data">2021-Sept-29</td>
                                    <td className="billing-table-data">Active</td>
                                    <td className="billing-table-data-download">Download Invoice</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>    
    )

};

export default BillingHistory