/**
 * File: Layout.js
 * Description: This component defines the sidebar, topbar and layout of the page.
 * Author: Sulakshanee Theja
 * Created: 26/3/2024
 * Last Modified: 26/3/2024 Sulakshanee Theja
 */

import React, { useState } from 'react';
import SideBar from '../sidebar/SideBar';
import TopBar from '../topbar/TopBar';
import '../../styles/layout.scss';
import { useLocation, Outlet } from 'react-router-dom';

const Layout = () => {
  const location = useLocation();

  const [sidebar, setSidebar] = useState(false);

  let pathNameArray = location.pathname.toString().split('/');
  let currentPath = pathNameArray[1];

  const mainContainer = sidebar ? 'container-sidebar-toggled' : 'container-sidebar-nottoggled';

  return (
    <div className="p-0 m-0 container-fluid main-container">
      <div className="p-0 m-0 row">
        <div className="p-0 m-0 col-lg-2 main-sidebar-grid">
          {true && <SideBar sidebar={sidebar} setSidebar={setSidebar} />}
        </div>

        <div className="p-0 m-0 col-lg-10 main-content-grid">
          <div className={mainContainer}>
            <div className="p-0 m-0 row sticky-top">
              {true && <TopBar currentPath={currentPath} />}
            </div>

            <div className="p-0 m-0 row">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
