/**
 * File: ForgotPassword.js
 * Description: This file contains the forgot password component responsible for entering the user email to send
 *              the reset password link to the user email.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 16/8/2024 Sulakshanee Theja
 */

import React, { useState } from 'react';
import ParaqumLogo from '../../assets/logos/paraqumLogo.jpg';
import '../../styles/auth.scss';
import { sendPasswordResetLink } from '../../api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { HTTP_STATUS_OK } from '../../constants/httpStatusCodeConstants';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [sendMailError, setSendMailError] = useState();
  const [isLinkSent, setIsLinkSent] = useState(false);

  const validateEmail = () => {
    let errors = {};

    if (!email) {
      errors.email = 'Email required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      errors.email = 'Email is invalid';
    }

    return errors;
  };

  const sendPasswordResetLinkFunc = (e) => {
    setSendMailError('');
    e.preventDefault();
    const isValid = validateEmail();

    if (isValid.email) {
      setErrors(isValid);
    } else {
      setErrors('');
      sendPasswordResetLink(email)
        .then((res) => {
          if (res.status === HTTP_STATUS_OK) {
            setIsLinkSent(true);
            Swal.fire({
              icon: 'success',
              text: 'Password Reset Link Sent Successfully ! Check your Email.',
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
          }
        })
        .catch((error) => {
          setSendMailError(error.response.data.message);
        });
    }
  };

  return (
    <div className="p-0 m-0 row container-fluid auth-main-container">
      <div className="p-0 m-0 col-md-6 logo-container">
        <img src={ParaqumLogo} alt="Paraqum Logo" className="logo-image" />
      </div>

      <div className="p-0 m-0 col-md-6 auth-form-container">
        {!isLinkSent ? (
          <div className="auth-form-sub-container">
            <div className="header-text">Password Reset</div>

            <div className="auth-form">
              <div className="auth-form-field-container">
                <label className="auth-form-label" htmlFor="email">
                  Enter your email address
                </label>
                <div className="auth-form-field">
                  <input
                    type="text"
                    className="auth-field-input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors({ ...errors, email: '' });
                      setSendMailError('');
                    }}
                  />
                </div>
                {errors.email && <p className="auth-field-error">{errors.email}</p>}
              </div>

              {sendMailError && <p className="mt-4 auth-field-error">{sendMailError}</p>}

              <button className="auth-form-button" onClick={sendPasswordResetLinkFunc}>
                Reset Password
              </button>
            </div>
          </div>
        ) : (
          <div className="check-email-container">
            <div className="check-email-text">
              A code to reset your password has just been sent to your email address. Please check
              your email. Note that the reset code is re-generated on each request.
            </div>

            <div className="redirect-container">
              <button className="auth-form-button" onClick={() => navigate('/')}>
                Login
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
