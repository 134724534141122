/**
 * File: PasswordResetError.js
 * Description: This file contains a component to display the error message of unqualified password reset link.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 26/3/2024 Sulakshanee Theja
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/errorOccurred.scss';

const PasswordResetError = () => {
  const navigate = useNavigate();

  return (
    <div className="error-page-main-container">
      <div className="error-page-sub-container">
        <div className="error-page-title">Error Occurred</div>

        <div>
          This password has already been reset or the reset period has expired.<br></br>Use the new
          password or try to find the old password again.
        </div>

        <div className="error-page-button-container">
          <button className="error-page-button" onClick={() => navigate('/')}>
            Try again
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetError;
