/**
 * File: paymentConstants.js
 * Description: Payment related constants
 * Author: Sulakshanee Theja
 * Created: 30/4/2024
 * Last Modified: 30/4/2024 Sulakshanee Theja
 */

// Payment confirmation
export const PAYMENT_CONFIRMATION = {
  PROCESSING: 0,
  DONE: 1,
  ERROR: 2,
};
