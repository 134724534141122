/**
 * File: httpStatusCodeConstants.js
 * Description: This file defines constants for common HTTP status codes.
 * Author: Sulakshanee Theja
 * Created: 18/12/2023
 * Last Modified: 30/4/2024 Sulakshanee Theja
 */

export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_CREATED = 201;
export const HTTP_STATUS_NO_CONTENT = 204;
export const HTTP_STATUS_BAD_REQUEST = 400;
export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_FORBIDDEN = 403;
export const HTTP_STATUS_NOT_FOUND = 404;
export const HTTP_STATUS_INTERNAL_SERVER_ERROR = 500;
export const HTTP_STATUS_SERVICE_UNAVAILABLE = 503;
