/**
 * File: Step2Generic.js
 * Description: Select the generic type to create tickets.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 5/2/2024 Sulakshanee Theja
 */

import React, { useCallback, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Swal from 'sweetalert2';
import { TICKET_GENERIC_TYPE } from '../../../../constants/ticketConstants';

const Step2Generic = ({ step, setStep, genericType, setGenericType }) => {
  const [isHovering, setIsHovering] = useState('');

  const handleMouseEnter = (value) => {
    setIsHovering(value);
  };

  const handleMouseLeave = (value) => {
    setIsHovering(value);
  };

  // To go to the next step
  const nextStep = useCallback(() => {
    if (genericType !== null) {
      setStep(step + 1);
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select a generic type to proceed.',
        confirmButtonColor: '#024b77',
      });
    }
  }, [genericType, setStep, step]);

  // To go to the previous step
  const previousStep = useCallback(() => {
    setStep(step - 1);
  }, [setStep, step]);

  return (
    <>
      <div className="step-title">Specify the Generic Type</div>

      <div className="description">
        Select whether the issue is Software-related, Hardware-related or Other.
      </div>

      <div
        className="generic-type"
        onClick={() => {
          setGenericType(TICKET_GENERIC_TYPE.SOFTWARE_ISSUE);
          nextStep();
        }}
        style={{
          backgroundColor:
            genericType === TICKET_GENERIC_TYPE.SOFTWARE_ISSUE ||
            isHovering === TICKET_GENERIC_TYPE.SOFTWARE_ISSUE
              ? '#0aaeef1a'
              : '#FFFFFF',
        }}
        onMouseEnter={() => handleMouseEnter(TICKET_GENERIC_TYPE.SOFTWARE_ISSUE)}
        onMouseLeave={() => handleMouseLeave('')}>
        <div className="generic-type-title">Software Issue</div>
        <div className="generic-type-description">Software related problems</div>
      </div>
      <div
        className="generic-type"
        onClick={() => {
          setGenericType(TICKET_GENERIC_TYPE.HARDWARE_ISSUE);
          nextStep();
        }}
        style={{
          backgroundColor:
            genericType === TICKET_GENERIC_TYPE.HARDWARE_ISSUE ||
            isHovering === TICKET_GENERIC_TYPE.HARDWARE_ISSUE
              ? '#0aaeef1a'
              : '#FFFFFF',
        }}
        onMouseEnter={() => handleMouseEnter(TICKET_GENERIC_TYPE.HARDWARE_ISSUE)}
        onMouseLeave={() => handleMouseLeave('')}>
        <div className="generic-type-title">Hardware Issue</div>
        <div className="generic-type-description">Hardware related problems</div>
      </div>
      <div
        className="generic-type"
        onClick={() => {
          setGenericType(TICKET_GENERIC_TYPE.OTHER);
          nextStep();
        }}
        style={{
          backgroundColor:
            genericType === TICKET_GENERIC_TYPE.OTHER || isHovering === TICKET_GENERIC_TYPE.OTHER
              ? '#0aaeef1a'
              : '#FFFFFF',
        }}
        onMouseEnter={() => handleMouseEnter(TICKET_GENERIC_TYPE.OTHER)}
        onMouseLeave={() => handleMouseLeave('')}>
        <div className="generic-type-title">Other</div>
        <div className="generic-type-description">
          Problems other than Software and Hardware related problems
        </div>
      </div>

      <div className="generic-2-arrow-container">
        <Icon.ArrowLeft className="arrow-button" color="#0D2841" size={24} onClick={previousStep} />
      </div>
    </>
  );
};

export default Step2Generic;
