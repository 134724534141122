/**
 * File: deviceConstants.js
 * Description: Client's devices and bill related constants.
 * Author: Sulakshanee Theja
 * Created: 8/2/2024
 * Last Modified: 8/2/2024 Sulakshanee Theja
 */

// Device pricing type
export const PRICING_TYPE = {
  PAY_AS_YOU_GO: 0,
  FIXED: 1,
};

// Bill status
export const BILL_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  PAID: 2,
};
