/**
 * File: clientConstants.js
 * Description: This file defines the client related constants.
 * Author: Sulakshanee Theja
 * Created: 15/12/2023
 * Last Modified: 15/12/2023 Sulakshanee Theja
 */

export const DEFAULT_ZIP_CODE = 999;
export const DEFAULT_STATE = 'UNKNOWN';
