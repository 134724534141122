/**
 * File: dateUtils.js
 * Description: This file contains utility functions for date manipulation and formatting.
 * Author: Sulakshanee Theja
 * Created: 8/2/2024
 * Last Modified: 8/2/2024 Sulakshanee Theja
 */

import { MONTH_ARRAY } from '../constants';

export function generateMonthArray() {
  const monthArray = MONTH_ARRAY.map((month, index) => ({
    value: index + 1,
    label: month,
  }));

  return monthArray;
}

export function getPastYearsWithCurrent(numberOfYears) {
  const currentYear = new Date().getFullYear();
  const pastYears = [];

  for (let i = 0; i <= numberOfYears; i++) {
    pastYears.push(currentYear - i);
  }

  return pastYears;
}
