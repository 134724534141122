/**
 * File: Tickets.js
 * Description: This file is defined to display all tickets and ticket details of the client. Also includes button to go to create new
 *              ticket page.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 26/3/2024 Sulakshanee Theja
 *
 * Ticket Types   --->  Generic = 1
 *                      On a Product = 2
 *
 * Generic Types  --->  Software Issue = 1
 *                      Hardware Issue = 2
 *                      Other == 3
 *
 * Status         --->  New = 0
 *                      In Progress = 1
 *                      Closed = 2
 *
 * Priority       --->  High = 3
 *                      Medium = 2
 *                      Low = 1
 */

import React, { useState, useEffect, useCallback } from 'react';
import '../../styles/tickets.scss';
import * as Icon from 'react-bootstrap-icons';
import TicketDetails from './components/ticketDetails/TicketDetails';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getTicketsByClientId, getAllDevices, getProfile, getCommentsByTicketId } from '../../api';
import { TICKET_GENERIC_TYPE, TICKET_PRIORITY, TICKET_TYPE } from '../../constants/ticketConstants';
import { useParams } from 'react-router-dom';

const Tickets = () => {
  const params = useParams();
  const urlTicketId = params.id;

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ticketId, setTicketId] = useState('');
  const [ticket, setTicket] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [ticketsByPage, setTicketsByPage] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);
  const [ticketComments, setTicketComments] = useState([]);

  const fetchTicketsData = useCallback(() => {
    setLoading(true);

    getTicketsByClientId()
      .then((res) => {
        if (!res.data.error) {
          // Sorting the array of tickets by updated date
          const clientTickets = res.data.data
            ?.map((clientTicket) => {
              return { ...clientTicket, date: new Date(clientTicket.updated) };
            })
            .sort((a, b) => b.date - a.date);

          setTicketList(clientTickets);
          setPageCount(
            clientTickets.length % 10 > 0
              ? parseInt(clientTickets.length / 10) + 1
              : parseInt(clientTickets.length / 10),
          );

          if (clientTickets.length !== 0) {
            setTicket(clientTickets[0]);
            setTicketId(clientTickets[0].id);
          }

          setPage(1);
          setLoading(false);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const fetchOtherData = useCallback(() => {
    getAllDevices()
      .then((res) => {
        if (!res.data.error) {
          const allDevices = res.data.data;
          setDeviceList(allDevices);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    getProfile()
      .then((res) => {
        if (!res.data.error) {
          const clientData = res.data.data;
          setClientDetails(clientData[0]);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const fetchTicketComments = (id) => {
    getCommentsByTicketId(id)
      .then((res) => {
        if (!res.data.error) {
          const commentList = res.data.data;
          setTicketComments(commentList);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    setLoading(true);

    getTicketsByClientId()
      .then((res) => {
        if (!res.data.error) {
          // Sorting the array of tickets by updated date
          const clientTickets = res.data.data
            ?.map((clientTicket) => {
              return { ...clientTicket, date: new Date(clientTicket.updated) };
            })
            .sort((a, b) => b.date - a.date);

          setTicketList(clientTickets);
          setPageCount(
            clientTickets.length % 10 > 0
              ? parseInt(clientTickets.length / 10) + 1
              : parseInt(clientTickets.length / 10),
          );

          if (urlTicketId !== undefined) {
            const index = clientTickets.findIndex((d) => parseInt(d.id) === parseInt(urlTicketId));

            const currentPage =
              (index + 1) % 10 > 0 ? parseInt((index + 1) / 10) + 1 : parseInt((index + 1) / 10);
            setPage(currentPage);

            const slicedTicketArray = clientTickets.slice(currentPage * 10 - 10, currentPage * 10);
            setTicketsByPage(slicedTicketArray);
            setTicket(clientTickets.find((d) => parseInt(d.id) === parseInt(urlTicketId)));
            setTicketId(clientTickets.find((d) => parseInt(d.id) === parseInt(urlTicketId)).id);
          } else {
            if (clientTickets.length !== 0) {
              setTicket(clientTickets[0]);
              setTicketId(clientTickets[0].id);
            }
          }

          setLoading(false);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [urlTicketId]);

  useEffect(() => {
    const slicedTicketArray = ticketList.slice(page * 10 - 10, page * 10);

    setTicketsByPage(slicedTicketArray);
  }, [page, ticketList]);

  useEffect(() => {
    fetchOtherData();
  }, [fetchOtherData]);

  useEffect(() => {
    if (ticketId !== '') {
      fetchTicketComments(ticketId);
    }
  }, [ticketId]);

  // Handling ticket list pagination.
  const previousPage = () => {
    setPage((p) => {
      if (p === 1) return p;
      return p - 1;
    });
  };

  const nextPage = () => {
    setPage((p) => {
      if (p === pageCount) return p;
      return p + 1;
    });
  };

  return (
    <div className="m-0 container-fluid main-body-container">
      <Link to="/create-ticket" className="new-ticket-button">
        <Icon.Plus color="#FFFFFF" size={28} />
        <div>New Ticket</div>
      </Link>

      <div className="row p-0 m-0 tickets-container">
        <div className="p-0 col-md-3 ticket-list">
          <div className="p-3 mx-0 created-tickets-container">
            <div className="created-tickets-container-title">Your Tickets</div>
            {ticketList.length !== 0 ? (
              <div className="created-tickets-container-pagecount">
                Page {page} of {pageCount}
                <Icon.ChevronLeft size={15} onClick={previousPage} disabled={page === 1} />
                <Icon.ChevronRight size={15} onClick={nextPage} disabled={page === pageCount} />
              </div>
            ) : null}
          </div>

          {ticketsByPage?.length !== 0
            ? ticketsByPage?.map((ticket, key) => {
                var device = [];
                if (parseInt(ticket.type) === TICKET_TYPE.ON_A_PRODUCT_TICKET) {
                  device =
                    ticket.device &&
                    deviceList &&
                    deviceList.filter((device) => device.uuid === ticket.device)[0];
                }

                return (
                  <div
                    key={key}
                    className="ticket-list-container"
                    style={{
                      backgroundColor: ticketId === ticket.id ? '#EBEBEB' : '#FFFFFF',
                    }}
                    onClick={() => {
                      setTicketId(ticket.id);
                      setTicket(ticketList.filter((t) => t.id === ticket.id)[0]);
                    }}>
                    <Icon.ShieldExclamation
                      className="p-0 col-md-2"
                      style={{
                        color:
                          ticket.priority === TICKET_PRIORITY.HIGH
                            ? '#EF5146'
                            : ticket.priority === TICKET_PRIORITY.MEDIUM
                            ? '#0B9919'
                            : '#FBC21D',
                      }}
                      size={25}
                    />

                    <div className="p-0 col-md-10">
                      <div className="ticket-title">{ticket.subject} </div>

                      <div className="ticket-subject-details">
                        <div>{moment(ticket?.created).format('MMM Do YY')}</div>
                        {parseInt(ticket.type) === TICKET_TYPE.ON_A_PRODUCT_TICKET ? (
                          <div>{device?.device_name}</div>
                        ) : (
                          <div>
                            {parseInt(ticket.generic_type) === TICKET_GENERIC_TYPE.SOFTWARE_ISSUE
                              ? 'Software Issue'
                              : parseInt(ticket.generic_type) === TICKET_GENERIC_TYPE.HARDWARE_ISSUE
                              ? 'Hardware Issue'
                              : 'Other'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        {loading === false ? (
          ticket && ticketList.length !== 0 ? (
            <div className="p-0 col-md-9 ticket-details-container">
              <TicketDetails
                ticketId={ticketId}
                ticket={ticket}
                deviceList={deviceList}
                clientDetails={clientDetails}
                ticketComments={ticketComments}
                fetchTicketComments={fetchTicketComments}
                fetchOtherData={fetchOtherData}
                fetchTicketsData={fetchTicketsData}
              />
            </div>
          ) : (
            <div className="p-0 col-md-9 ticket-no-data-container">
              <Icon.ExclamationTriangle size={40} />
              <div>No Tickets Found</div>
            </div>
          )
        ) : (
          <div className="p-0 col-md-9 ticket-loading-container">
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tickets;
