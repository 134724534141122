/**
 * File: useLocalStorage.js
 * Description: This file contains a custom hook for managing local storage values.
 * Author: John Lesley
 * Created: 18/12/2023
 * Last Modified: 18/12/2023 John Lesley
 */

import { useState } from 'react';

function useLocalStorage(key, initialValue) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? storedValue : initialValue;
  });

  const setStoredValue = (newValue) => {
    localStorage.setItem(key, newValue);
    setValue(newValue);
  };

  return [value, setStoredValue];
}

export default useLocalStorage;
