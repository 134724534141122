/**
 * File: ResetPassword.js
 * Description: This file contains the reset password component responsible for changing the forgotten password.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 16/8/2024 Sulakshanee Theja
 */

import React, { useState } from 'react';
import ParaqumLogo from '../../assets/logos/paraqumLogo.jpg';
import '../../styles/auth.scss';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetPassword } from '../../api';
import Swal from 'sweetalert2';
import { PASSWORD_TYPE } from '../../constants/authConstants';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId, code } = location.state || {};

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [passwordType, setPasswordType] = useState(PASSWORD_TYPE.PASSWORD);
  const [confirmPasswordType, setConfirmPasswordType] = useState(PASSWORD_TYPE.PASSWORD);
  const [errors, setErrors] = useState({});

  const validateResetPassword = () => {
    let errors = {};

    if (!password) {
      errors.password = 'Password required';
    }

    if (!confirmPassword) {
      errors.confirmPassword = 'Confirm Password required';
    }

    return errors;
  };

  const passwordReset = (e) => {
    e.preventDefault();
    const isValid = validateResetPassword();

    if (isValid.password || isValid.confirmPassword) {
      setErrors(isValid);
    } else {
      setErrors('');

      const body = {
        uuid: clientId,
        code: code,
        password: password,
        verifiedPassword: confirmPassword,
      };

      if (password !== confirmPassword) {
        Swal.fire({
          icon: 'error',
          text: 'Password mismatch. Check your Confirmation Password',
          timer: 3000,
          confirmButtonColor: '#024b77',
        });
      } else {
        resetPassword(body)
          .then((res) => {
            if (!res.data.error) {
              Swal.fire({
                icon: 'success',
                text: 'Password Reset Successfully !',
                timer: 3000,
                confirmButtonColor: '#024b77',
              });
              navigate('/');
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              text: err.response.data.message,
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
          });
      }
    }
  };

  return (
    <div className="p-0 m-0 row container-fluid auth-main-container">
      <div className="p-0 m-0 col-md-6 logo-container">
        <img src={ParaqumLogo} alt="Paraqum Logo" className="logo-image" />
      </div>

      <div className="p-0 m-0 col-md-6 auth-form-container">
        <div className="auth-form-sub-container">
          <div className="header-text">Reset Password</div>

          <div className="auth-form">
            <div className="auth-form-field-container">
              <label className="auth-form-label" htmlFor="password">
                Password
              </label>
              <div className="auth-form-field">
                <input
                  type={passwordType}
                  className="auth-field-input"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrors({ ...errors, password: '' });
                  }}
                />
                {hidePassword ? (
                  <Icon.EyeSlash
                    color="#8893a0"
                    size={20}
                    onClick={() => {
                      setHidePassword(false);
                      setPasswordType(PASSWORD_TYPE.TEXT);
                    }}
                  />
                ) : (
                  <Icon.Eye
                    color="#8893a0"
                    size={20}
                    onClick={() => {
                      setHidePassword(true);
                      setPasswordType(PASSWORD_TYPE.PASSWORD);
                    }}
                  />
                )}
              </div>
              {errors.password && <p className="auth-field-error">{errors.password}</p>}
            </div>

            <div className="auth-form-field-container">
              <label className="auth-form-label" htmlFor="confirmPassword">
                Confirm Password
              </label>
              <div className="auth-form-field">
                <input
                  type={confirmPasswordType}
                  className="auth-field-input"
                  placeholder="********"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setErrors({ ...errors, confirmPassword: '' });
                  }}
                />
                {hideConfirmPassword ? (
                  <Icon.EyeSlash
                    color="#8893a0"
                    size={20}
                    onClick={() => {
                      setHideConfirmPassword(false);
                      setConfirmPasswordType(PASSWORD_TYPE.TEXT);
                    }}
                  />
                ) : (
                  <Icon.Eye
                    color="#8893a0"
                    size={20}
                    onClick={() => {
                      setHideConfirmPassword(true);
                      setConfirmPasswordType(PASSWORD_TYPE.PASSWORD);
                    }}
                  />
                )}
              </div>
              {errors.confirmPassword && (
                <p className="auth-field-error">{errors.confirmPassword}</p>
              )}
            </div>

            <button className="auth-form-button" onClick={passwordReset}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
