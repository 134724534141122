/**
 * File: RecentBills.js
 * Description: This file contains the "RecentBills" component responsible for displaying the table that includes recent bill details, bill
 *              status and download invoice functionality.
 * Author: Nethmi Aththanayake
 * Created: 22/1/2023
 * Last Modified: 27/6/2024 Sulakshanee Theja
 */

import React from 'react';
import '../../../styles/billingStyles.scss';
import moment from 'moment';
import { BASE_URL } from '../../../constants';
import { MONTH_ARRAY } from '../../../constants';
import { BILL_STATUS, PRICING_TYPE } from '../../../constants/deviceConstants';

const RecentBills = ({
  recentBills,
  loading,
  setSelectedBill,
  selectedBillId,
  setSelectedBillId,
  selectedDevicePricingType,
  selectedDeviceCurrencySymbol,
}) => {
  const getFullMonthName = (month) => {
    if (month >= 1 && month <= 12) {
      return MONTH_ARRAY[month - 1];
    } else {
      return 'Invalid Month';
    }
  };

  return (
    <div className="device-bill-container">
      <div className="recent-bills-heading">Recent Invoices</div>

      <table className="table">
        <thead>
          <tr>
            <th className="recent-bills-table-head">Billing Month</th>
            <th className="recent-bills-table-head">Amount</th>
            <th className="recent-bills-table-head">Payment Due Date</th>
            {selectedDevicePricingType !== PRICING_TYPE.FIXED && (
              <th className="recent-bills-table-head">Subscriber Count</th>
            )}
            <th className="recent-bills-table-head">Payment Date</th>
            <th className="recent-bills-table-head">Status</th>
            <th className="recent-bills-table-head">Action</th>
          </tr>
        </thead>

        <tbody>
          {loading === false ? (
            recentBills.map((recentBill, index) => {
              return (
                <tr
                  key={index}
                  className="align-middle"
                  style={{
                    '--bs-table-bg':
                      selectedBillId === recentBill.invoice_id ? '#ebebeb' : '#FFFFFF',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSelectedBill(recentBill);
                    setSelectedBillId(recentBill.invoice_id);
                  }}>
                  <td className="recent-bills-table-body">
                    {getFullMonthName(recentBill.month)}-
                    {recentBill.year.toString().padStart(4, '0')}
                  </td>
                  <td className="recent-bills-table-body">
                    {recentBill.status !== BILL_STATUS.PENDING
                      ? selectedDeviceCurrencySymbol +
                        ' ' +
                        parseFloat(recentBill.net_total).toFixed(2)
                      : '-'}
                  </td>
                  <td className="recent-bills-table-body">
                    {moment(recentBill.due_date).format('DD/MM/YYYY')}
                  </td>
                  {selectedDevicePricingType !== PRICING_TYPE.FIXED && (
                    <td className="recent-bills-table-body">{recentBill.subscribers}</td>
                  )}
                  <td className="recent-bills-table-body">
                    {recentBill.payment_date
                      ? moment(recentBill.payment_date).format('DD/MM/YYYY')
                      : '-'}
                  </td>
                  <td className="recent-bills-table-body">
                    <span
                      className="recent-bills-status-label"
                      style={{
                        backgroundColor:
                          recentBill.status === BILL_STATUS.PENDING
                            ? '#ffa500'
                            : recentBill.status === BILL_STATUS.APPROVED
                            ? '#33b249'
                            : '#0572cc',
                      }}>
                      {recentBill.status === BILL_STATUS.PENDING
                        ? 'Processing'
                        : recentBill.status === BILL_STATUS.APPROVED
                        ? 'Approved'
                        : 'Paid'}
                    </span>
                  </td>
                  <td className="recent-bills-table-body">
                    {recentBill.token ? (
                      <a
                        href={BASE_URL + '/invoice/' + recentBill.token}
                        style={{ textDecoration: 'none' }}>
                        Download Invoice
                      </a>
                    ) : (
                      'Processing Approval'
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="recent-bills-table-loading">
              <td colSpan={7}>
                <div className="spinner-border text-primary" role="status"></div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RecentBills;
