/**
 * File: Step4.js
 * Description: Add attachments to create tickets.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 4/10/2024 Sulakshanee Theja
 */

import React, { useCallback } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Step4 = ({ step, setStep, setFiles, isButtonDisabled, createNewTicket }) => {
  const navigate = useNavigate();

  // To go to the previous step
  const previousStep = useCallback(() => {
    setStep(step - 1);
  }, [setStep, step]);

  // Cancellation of ticket creation
  const handleCancel = useCallback(() => {
    Swal.fire({
      icon: 'question',
      text: 'Are you sure you want to cancel ?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      confirmButtonColor: '#024b77',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/ticket');
      }
    });
  }, [navigate]);

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  return (
    <>
      <div className="step-title">Attachments</div>

      <div className="description">
        You may attach a .pdf/.zip/.jpeg/.png file to this form submission.
      </div>

      <div className="my-5 py-5">
        <input
          className="form-control"
          type="file"
          id="formFile"
          name="file"
          style={{ borderColor: '#094d76' }}
          accept="image/*, .pdf, .zip"
          multiple
          onChange={handleFileChange}
        />
      </div>

      <div className="step-4-arrow-container">
        <Icon.ArrowLeft className="arrow-button" color="#0D2841" size={24} onClick={previousStep} />

        <div className="ticket-buttons-container">
          <button type="button" style={{ background: '#B3B9BF' }} onClick={handleCancel}>
            Cancel
          </button>
          <button
            type="submit"
            style={{ background: '#024b77', color: '#FFFFFF' }}
            onClick={createNewTicket}
            disabled={isButtonDisabled}>
            Create
          </button>
        </div>
      </div>
    </>
  );
};

export default Step4;
