/**
 * File: StepComponent.js
 * Description: Shows the ticketing steps in ticket creation.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 5/2/2024 Sulakshanee Theja
 */

import React from 'react';
import * as Icon from 'react-bootstrap-icons';

const StepComponent = ({ number, step }) => {
  return (
    <div
      className="step-number-container"
      style={{
        backgroundColor: number <= step ? '#024B77' : '#FFF',
        color: number === step ? '#FFF' : '#0aaeef',
      }}>
      {number < step ? <Icon.Check color="#FFF" size={48} /> : number}
    </div>
  );
};

export default StepComponent;
