/**
 * File: SelectedBillDetails.js
 * Description: This file contains the "SelectedBillDetails" component responsible for displaying the current bill details for the
 *              selected device. Also includes functions for paying the current bill and downloading the invoice.
 * Author: John Lesley
 * Author: Nethmi Aththanayake
 * Created: 22/1/2023
 * Last Modified: 30/7/2024 Sulakshanee Theja
 */

import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import '../../../styles/billingStyles.scss';
import SelectedBill from './SelectedBill';
import moment from 'moment';
import { BASE_URL } from '../../../constants';
import { BILL_STATUS, PRICING_TYPE } from '../../../constants/deviceConstants';

const SelectedBillDetails = ({
  selectedBill,
  selectedDevicePricingType,
  selectedDeviceCurrencySymbol,
}) => {
  const [click, setClick] = useState(false);

  const handleSelectedBillModal = () => {
    setClick(true);
  };

  return (
    <div className="device-bill-container">
      <div className="p-0 m-0 row">
        <div className="p-0 m-0 col-lg-6">
          <div>
            <div className="bill-details-label">Billing Date</div>
            <div className="bill-details-value">
              {selectedBill.billing_date
                ? moment(selectedBill.billing_date).format('DD/MM/YYYY')
                : '-'}
            </div>
          </div>

          <div>
            <div className="bill-details-label">Payment Due Date</div>
            <div className="bill-details-value">
              {moment(selectedBill.due_date).format('DD/MM/YYYY')}
            </div>
          </div>

          <div>
            <div className="bill-details-label">Approved Date</div>
            <div className="bill-details-value">
              {selectedBill.approved_date
                ? moment(selectedBill.approved_date).format('DD/MM/YYYY')
                : 'Not Approved'}
            </div>
          </div>
        </div>

        <div className="p-0 m-0 col-lg-6">
          {selectedDevicePricingType !== PRICING_TYPE.FIXED && (
            <div>
              <div className="bill-details-label">Subscriber Count</div>
              <div className="bill-details-value">
                {selectedBill.subscribers === 0 ? '-' : selectedBill.subscribers}
              </div>
            </div>
          )}

          <div>
            <div className="bill-details-label">Subscription Status</div>
            <div className="bill-details-value">
              {selectedBill.status === BILL_STATUS.PENDING
                ? 'Processing'
                : selectedBill.status === BILL_STATUS.APPROVED
                ? 'Approved'
                : 'Paid'}
            </div>
          </div>

          {selectedBill.status !== BILL_STATUS.PENDING && (
            <div>
              <div className="bill-details-label">Current Bill</div>
              <div className="curent-bill-value-container">
                <div className="bill-details-value">
                  {selectedDeviceCurrencySymbol} {parseFloat(selectedBill.net_total).toFixed(2)}
                </div>
                <Icon.ExclamationCircle
                  size={16}
                  onClick={handleSelectedBillModal}
                  className="current-bill-icon"
                />
              </div>

              <SelectedBill
                click={click}
                setClick={setClick}
                selectedBill={selectedBill}
                selectedDeviceCurrencySymbol={selectedDeviceCurrencySymbol}
              />
            </div>
          )}
        </div>
      </div>

      <div className="p-0 m-0 row">
        <div className="p-0 m-0 col-lg-6">
          {selectedBill.payment_link && selectedBill.status !== BILL_STATUS.PAID && (
            <a
              href={selectedBill.payment_link}
              target="_blank"
              rel="noopener noreferrer"
              className="current-bill-button-link">
              <div className="current-bill-button pay-now-button">Pay Now</div>
            </a>
          )}
        </div>

        <div className="p-0 m-0 col-lg-6">
          {selectedBill.token ? (
            <a
              href={BASE_URL + '/invoice/' + selectedBill.token}
              className="current-bill-button-link">
              <div className="current-bill-button download-button">Download Invoice</div>
            </a>
          ) : (
            <div className="current-bill-button not-approved-button">Processing Approval</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectedBillDetails;
