/**
 * File: Step3OnProduct.js
 * Description: Add specific ticket information to create tickets.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 5/2/2024 Sulakshanee Theja
 */

import React, { useState, useCallback } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

const Step3OnProduct = ({
  step,
  setStep,
  user,
  setUser,
  setName,
  email,
  setEmail,
  phone,
  setPhone,
  priority,
  setPriority,
  subject,
  setSubject,
  description,
  setDescription,
  device,
  contactDetails,
  clientDetails,
  priorityOptions,
  contactPersonOptions,
}) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 30,
      backgroundColor: '#FFFFFF',
      fontWeight: 400,
      fontSize: 18,
      borderColor: '#929596',
      borderRadius: 5,
      borderWidth: 1.5,
      outline: 0,
    }),
    option: (base) => ({
      ...base,
      color: '#000000',
    }),
  };

  const errorStyles = {
    control: (base) => ({
      ...base,
      height: 30,
      backgroundColor: '#FFFFFF',
      fontWeight: 400,
      fontSize: 18,
      borderColor: '#D00000',
      borderRadius: 5,
      borderWidth: 1.5,
      outline: 0,
    }),
    option: (base) => ({
      ...base,
      color: '#000000',
    }),
  };

  const [errors, setErrors] = useState([]);

  const validateTicketInformation = useCallback(() => {
    let errors = {};

    if (!user) {
      errors.user = 'User is required';
    }

    if (!subject) {
      errors.subject = 'Ticket title is required';
    }

    if (!description) {
      errors.description = 'Description is required';
    }

    return errors;
  }, [user, subject, description]);

  // To go to the next step
  const nextStep = useCallback(() => {
    const errors = validateTicketInformation();

    if (errors.user || errors.subject || errors.description) {
      setErrors(errors);
    } else {
      setStep(step + 1);
    }
  }, [setStep, validateTicketInformation, step]);

  // To go to the previous step
  const previousStep = useCallback(() => {
    setStep(step - 1);
  }, [setStep, step]);

  const userOptions = [
    { label: clientDetails.name, value: clientDetails.uuid },
    {
      label: 'Contact Persons',
      options: contactPersonOptions,
    },
  ];

  // Set specific user details
  const handleUserChange = (e) => {
    setUser(clientDetails.uuid);

    if (clientDetails.uuid === e.value) {
      setName(clientDetails.name);
      setEmail(clientDetails.email);
      setPhone(clientDetails.phone);
    } else {
      const contactPerson = contactDetails.filter(
        (contact) => parseInt(contact.id) === parseInt(e.value),
      )[0];
      setName(contactPerson.name);
      setEmail(contactPerson.email);
      setPhone(contactPerson.phone);
    }
  };

  return (
    <>
      <div className="step-title">Specify Ticket Information Here</div>

      <div className="client-details-container">
        <div className="mb-4 row">
          <div className="col-md-3">
            <div className="ticket-field-label d-md-flex flex-md-row">
              Name <span>:</span>
            </div>
          </div>
          <div className="col-md-9">
            <Select
              styles={errors.user ? errorStyles : customStyles}
              value={userOptions.map((option) => {
                if (user === clientDetails.uuid) {
                  return option;
                } else {
                  option.options?.filter((item) => {
                    return parseInt(item.value) === parseInt(user);
                  });
                }
              })}
              options={userOptions}
              onChange={(e) => {
                handleUserChange(e);
                setErrors({ ...errors, user: '' });
              }}
            />
          </div>
        </div>

        <div className="mb-4 row">
          <div className="col-md-3">
            <div className="ticket-field-label d-md-flex flex-md-row">
              Email <span>:</span>
            </div>
          </div>
          <div className="col-md-9">
            <Form.Control
              size="sm"
              className="ticket-field"
              type="text"
              placeholder=""
              value={email}
              disabled
            />
          </div>
        </div>

        <div className="mb-4 row">
          <div className="col-md-3">
            <div className="ticket-field-label d-md-flex flex-md-row">
              Contact <span>:</span>
            </div>
          </div>
          <div className="col-md-9">
            <Form.Control
              size="sm"
              className="ticket-field"
              type="text"
              placeholder=""
              value={phone}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="title fs-5 fw-bolder">Ticket Information</div>

      <div className="my-4 typeDiv">
        <div className="mb-4 row">
          <div className="col-md-3">
            <div className="ticket-field-label d-md-flex flex-md-row">
              Device <span>:</span>
            </div>
          </div>
          <div className="col-md-9">
            <Form.Control
              size="sm"
              className="ticket-field"
              type="text"
              placeholder=""
              value={device.label}
              disabled
            />
          </div>
        </div>

        <div className="mb-4 row">
          <div className="col-md-3">
            <div className="ticket-field-label d-md-flex flex-md-row">
              Priority <span>:</span>
            </div>
          </div>
          <div className="col-md-9">
            <Select
              value={priorityOptions.filter((item) => {
                return item.value === priority;
              })}
              onChange={(e) => {
                setPriority(e.value);
              }}
              isSearchable={true}
              options={priorityOptions}
              styles={customStyles}
            />
          </div>
        </div>

        <div className="mb-4 row">
          <div className="col-md-3">
            <div className="ticket-field-label d-md-flex flex-md-row">
              Subject <span>:</span>
            </div>
          </div>
          <div className="col-md-9">
            <Form.Control
              style={{ borderColor: errors.subject ? '#D00000' : '#929596' }}
              size="sm"
              className="ticket-field"
              type="text"
              placeholder=""
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
                setErrors({ ...errors, subject: '' });
              }}
            />
          </div>
        </div>

        <div className="mb-4 row">
          <div className="col-md-3">
            <div className="ticket-field-label d-md-flex flex-md-row">
              Description <span>:</span>
            </div>
          </div>
          <div className="col-md-9">
            <Form.Control
              style={{ borderColor: errors.description ? '#D00000' : '#929596', height: '175px' }}
              size="sm"
              className="ticket-field"
              placeholder=""
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setErrors({ ...errors, description: '' });
              }}
              as="textarea"
              rows="5"
            />
          </div>
        </div>
      </div>

      <div className="step-3-arrow-container">
        <Icon.ArrowLeft className="arrow-button" color="#0D2841" size={24} onClick={previousStep} />
        <Icon.ArrowRight className="arrow-button" color="#0D2841" size={24} onClick={nextStep} />
      </div>
    </>
  );
};

export default Step3OnProduct;
