/**
 * File: CreateTickets.js
 * Description: This file contains the parent component of ticket creation.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 4/10/2024 Sulakshanee Theja
 *
 * Ticket Types     --->    Generic == 1
 *                          On a Product == 2
 *
 * Generic Types    --->    Software Issue == 1
 *                          Hardware Issue == 2
 *                          Other == 3
 *
 * Status           --->    New === 0
 *                          In Progress === 1
 *                          Closed === 2
 *
 * Priority         --->    High === 3
 *                          Medium === 2
 *                          Low === 1
 */

import React, { useState, useEffect } from 'react';
import '../../styles/createTicket.scss';
import { useNavigate } from 'react-router-dom';
import Step1 from './components/createTicketSteps/Step1';
import StepComponent from './components/createTicketSteps/StepComponent';
import Step2Generic from './components/createTicketSteps/Step2Generic';
import Step2OnProduct from './components/createTicketSteps/Step2OnProduct';
import Step3Generic from './components/createTicketSteps/Step3Generic';
import Step3OnProduct from './components/createTicketSteps/Step3OnProduct';
import Step4 from './components/createTicketSteps/Step4';
import { getAllDevices, getContacts, getProfile, addTicket } from '../../api';
import Swal from 'sweetalert2';
import {
  CREATED_BY,
  CREATE_TICKET,
  TICKET_PRIORITY,
  TICKET_TYPE,
} from '../../constants/ticketConstants';
import { HTTP_STATUS_OK } from '../../constants/httpStatusCodeConstants';

function CreateTicket() {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [user, setUser] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [ticketType, setTicketType] = useState('');
  const [genericType, setGenericType] = useState('');
  const [device, setDevice] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState(2);
  const [files, setFiles] = useState([]);

  const [clientDetails, setClientDetails] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);
  const [contactPersonOptions, setContactPersonOptions] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // Ticket priority types
  const priorityOptions = [
    { value: TICKET_PRIORITY.HIGH, label: 'High' },
    { value: TICKET_PRIORITY.MEDIUM, label: 'Medium' },
    { value: TICKET_PRIORITY.LOW, label: 'Low' },
  ];

  const generateDeviceOptions = (devices) => {
    return devices.map((device) => ({
      label: device.device_name,
      value: device.uuid,
    }));
  };

  const deviceOptions = React.useMemo(() => generateDeviceOptions(deviceList), [deviceList]);

  useEffect(() => {
    getAllDevices()
      .then((res) => {
        const allDevices = res.data.data;
        setDeviceList(allDevices);

        if (allDevices.length === 1) {
          const clientDevice = generateDeviceOptions(allDevices);
          setDevice(clientDevice[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getContacts()
      .then((res) => {
        const clientContactDetails = res.data.data;
        setContactDetails(clientContactDetails);

        let contactPersonsList = [];

        clientContactDetails &&
          clientContactDetails.map((contactPerson) => {
            contactPersonsList.push({
              value: contactPerson.id,
              label: contactPerson.name,
            });
          });

        setContactPersonOptions(contactPersonsList);
      })
      .catch((err) => {
        console.log(err);
      });

    getProfile()
      .then((res) => {
        if (!res.data.error) {
          const clientData = res.data.data;
          setClientDetails(clientData[0]);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const clearAll = () => {
    setUser('');
    setEmail('');
    setPhone('');
    setPriority('');
    setTicketType('');
    setGenericType(1);
    setDevice('');
    setSubject('');
    setFiles([]);
    setDescription('');
  };

  const createNewTicket = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);

    const data = new FormData();

    if (files.length !== 0) {
      [...files].forEach((file) => {
        data.append('attachments', file);
      });
    }

    data.append('user_id', user);
    data.append('email', email);
    data.append('contact', phone);
    data.append('name', name);
    data.append('datasource', 'PORTAL');
    data.append('priority', priority);
    data.append('type', ticketType);
    data.append('generic_type', genericType === '' ? null : genericType);
    data.append('device', device.value === undefined ? null : device.value);
    data.append('subject', subject);
    data.append('description', description);
    data.append('created_by_paraqum', CREATED_BY.CLIENT);

    try {
      const res = await addTicket(data);

      if (res && res.data && res.data.status === HTTP_STATUS_OK) {
        Swal.fire({
          icon: 'success',
          text: 'Ticket Created Successfully !',
          timer: 5000,
          confirmButtonColor: '#024b77',
        });
        clearAll();
        navigate('/ticket');
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Failed to Create Ticket !',
          timer: 5000,
          confirmButtonColor: '#024b77',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Failed to Create Ticket !',
        timer: 5000,
        confirmButtonColor: '#024b77',
      });
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="m-0 container-fluid main-body-container">
      {step === CREATE_TICKET.STEP_0 ? (
        <Step1
          step={step}
          setStep={setStep}
          ticketType={ticketType}
          setTicketType={setTicketType}
        />
      ) : (
        <div className="p-0 m-0 row">
          {/* Ticket creation step numbers */}
          <div className="col-md-2 step-container">
            <StepComponent number={1} step={step} />
            <div className="step-line"></div>
            <StepComponent number={2} step={step} />
            {deviceList.length === 1 && ticketType !== TICKET_TYPE.GENERIC_TICKET ? null : (
              <>
                <div className="step-line"></div>
                <StepComponent number={3} step={step} />
              </>
            )}
          </div>

          <div className="col-md-10 ticket-creation-body">
            <form>
              {ticketType === TICKET_TYPE.GENERIC_TICKET ? (
                step === CREATE_TICKET.STEP_1 ? (
                  <Step2Generic
                    step={step}
                    setStep={setStep}
                    genericType={genericType}
                    setGenericType={setGenericType}
                  />
                ) : step === CREATE_TICKET.STEP_2 ? (
                  <Step3Generic
                    step={step}
                    setStep={setStep}
                    user={user}
                    setUser={setUser}
                    setName={setName}
                    email={email}
                    setEmail={setEmail}
                    phone={phone}
                    setPhone={setPhone}
                    priority={priority}
                    setPriority={setPriority}
                    subject={subject}
                    setSubject={setSubject}
                    description={description}
                    setDescription={setDescription}
                    genericType={genericType}
                    contactDetails={contactDetails}
                    clientDetails={clientDetails}
                    priorityOptions={priorityOptions}
                    contactPersonOptions={contactPersonOptions}
                  />
                ) : (
                  <Step4
                    step={step}
                    setStep={setStep}
                    setFiles={setFiles}
                    isButtonDisabled={isButtonDisabled}
                    createNewTicket={createNewTicket}
                  />
                )
              ) : deviceList.length === 1 ? ( // Only if the client has more than one device, the 'Step2OnProduct' step is displayed
                step === CREATE_TICKET.STEP_1 ? (
                  <Step3OnProduct
                    step={step}
                    setStep={setStep}
                    user={user}
                    setUser={setUser}
                    setName={setName}
                    email={email}
                    setEmail={setEmail}
                    phone={phone}
                    setPhone={setPhone}
                    priority={priority}
                    setPriority={setPriority}
                    subject={subject}
                    setSubject={setSubject}
                    description={description}
                    setDescription={setDescription}
                    device={device}
                    contactDetails={contactDetails}
                    clientDetails={clientDetails}
                    priorityOptions={priorityOptions}
                    contactPersonOptions={contactPersonOptions}
                  />
                ) : (
                  <Step4
                    step={step}
                    setStep={setStep}
                    setFiles={setFiles}
                    isButtonDisabled={isButtonDisabled}
                    createNewTicket={createNewTicket}
                  />
                )
              ) : step === CREATE_TICKET.STEP_1 ? (
                <Step2OnProduct
                  step={step}
                  setStep={setStep}
                  device={device}
                  setDevice={setDevice}
                  deviceOptions={deviceOptions}
                />
              ) : step === CREATE_TICKET.STEP_2 ? (
                <Step3OnProduct
                  step={step}
                  setStep={setStep}
                  user={user}
                  setUser={setUser}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  phone={phone}
                  setPhone={setPhone}
                  priority={priority}
                  setPriority={setPriority}
                  subject={subject}
                  setSubject={setSubject}
                  description={description}
                  setDescription={setDescription}
                  device={device}
                  contactDetails={contactDetails}
                  clientDetails={clientDetails}
                  priorityOptions={priorityOptions}
                  contactPersonOptions={contactPersonOptions}
                />
              ) : (
                <Step4
                  step={step}
                  setStep={setStep}
                  setFiles={setFiles}
                  isButtonDisabled={isButtonDisabled}
                  createNewTicket={createNewTicket}
                />
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateTicket;
