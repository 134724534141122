/**
 * File: SubscriberCountGraph.js
 * Description: This file contains the implementation of the 'SubscriberCountGraph' component, which displays subscriber counts using the graph
 *              for a selected device over a selected time period.
 * Author: Nethmi Aththanayake
 * Created: 14/2/2023
 * Last Modified: 27/3/2024 Sulakshanee Theja
 */

import React, { useEffect, useState } from 'react';
import './../../../styles/billingStyles.scss';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getSubscriberCounts } from '../../../api';
import * as Icon from 'react-bootstrap-icons';
import { HTTP_STATUS_OK } from '../../../constants/httpStatusCodeConstants';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SubscriberCountGraph = ({ selectedDevice, selectedYear, selectedMonth }) => {
  const [subscriberCountLoading, setSubscriberCountLoading] = useState(false);
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Subscriber Count',
        data: [],
        fill: true,
        backgroundColor: 'rgb(70, 130, 180)',
        borderColor: 'rgb(70, 130, 180)',
        responsive: true,
      },
    ],
  });

  useEffect(() => {
    setSubscriberCountLoading(true);

    if (selectedDevice.uuid && selectedMonth !== '' && selectedYear !== '') {
      getSubscriberCounts({
        subscription: selectedDevice.uuid,
        month: parseInt(selectedMonth),
        year: parseInt(selectedYear),
      })
        .then((res) => {
          if (res.data && res.data.status === HTTP_STATUS_OK) {
            const graphDataList = res.data.data;

            // Update the graph data
            setGraphData({
              labels: graphDataList.map((graph) => graph.rec_day), // Replace with your actual date labels
              datasets: [
                {
                  label: 'Subscriber Count',
                  data: graphDataList.map((graph) => graph.subscribers), // Replace with your actual subscriber count data
                  fill: true,
                  backgroundColor: 'rgb(70, 130, 180)',
                  borderColor: 'rgb(70, 130, 180)',
                  responsive: true,
                },
              ],
            });

            setSubscriberCountLoading(false);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [selectedDevice, selectedMonth, selectedYear]);

  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {},
    },
    borderRadius: 5,
  };

  return (
    <>
      {subscriberCountLoading === false ? (
        graphData.datasets[0].data.length > 0 && graphData.labels.length > 0 ? (
          <div>
            <div className="subscriber-count-graph-title">Subscriber Count</div>
            <Bar data={graphData} options={options} height={110} />
          </div>
        ) : (
          <div className="no-data-container">
            <Icon.ExclamationTriangle size={20} />
            <div>Subscribers Not Available</div>
          </div>
        )
      ) : (
        <div className="loading-container">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      )}
    </>
  );
};

export default SubscriberCountGraph;
