/**
 * File: Dashboard.js
 * Description: This file contains count of Products, Tickets and Devices. Also contains Subscription details, License's Status
 *                 and Announcements.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 27/6/2024 Sulakshanee Theja
 */

import React, { useEffect, useState } from 'react';
import DashboardOverview from './components/DashboardOverview';
import DashboardLicenses from './components/DashboardLicenses';
import DashboardSubscriptions from './components/DashboardSubscriptions';
import '../../styles/dashboard.scss';
import { getAllDevices } from '../../api';

const Dashboard = () => {
  const userName = localStorage.getItem('userName');

  const [deviceList, setDeviceList] = useState([]);

  useEffect(() => {
    getAllDevices()
      .then((res) => {
        const allDevices = res.data.data;
        setDeviceList(allDevices);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="m-0 container-fluid main-body-container">
      <div className="dashboard-head">
        <div className="dashboard-welcome-text">Welcome {userName} !</div>
      </div>

      <div className="p-4 dashboard-body">
        <div className="row">
          <div className="col-md-8">
            <DashboardOverview deviceList={deviceList} />
            <DashboardSubscriptions deviceList={deviceList} />
          </div>

          <div className="col-md-4">
            <DashboardLicenses />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
