/**
 * File: TopBar.js
 * Description: This file defines the topbar component that displays page title, notification dropdown and profile icon.
 * Author: Nethmi Aththanayaka
 * Created: 22/8/2023
 * Last Modified: 26/3/2024 Sulakshanee Theja
 *  */

import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { NavDropdown } from 'react-bootstrap';
import '../../styles/topBar.scss';
import '../../styles/notifications.scss';
import { getTicketNotifications, readAllNotifications, readNotifications } from '../../api';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ParaqumLogoOnly from '../../assets/logos/paraqumLogoOnly.png';
import { NOTIFICATION_STATUS, NOTIFICATION_TYPE } from '../../constants/notificationConstants';

function TopBar({ currentPath }) {
  const navigate = useNavigate();
  const userName = localStorage.getItem('username');
  const uuid = localStorage.getItem('uuid');

  const [topic, setTopic] = useState('');
  const [notifications, setNotifications] = React.useState([]);
  const [isHovering, setIsHovering] = useState(false);

  // Split the title by slash
  var splitCurrentPath = currentPath.split('/');

  // Capitalize the first letter and keep the rest of the word in lowercase
  const currentPathTitle =
    splitCurrentPath[0].charAt(0).toUpperCase() + splitCurrentPath[0].slice(1);

  useEffect(() => {
    // This is recursion function used to remove %20 in domain
    const removeSpaces = async (value) => {
      if (value.includes('%20')) {
        removeSpaces(value.replace('%20', ' '));
      } else {
        await setTopic(value);
        return value;
      }
    };

    switch (currentPathTitle) {
      case 'Create-ticket':
        setTopic('Create Ticket');
        break;
      case 'Selectedproducts':
        setTopic('Selected Product');
        break;
      case 'Billing-history':
        setTopic('Billing History');
        break;
      default:
        if (currentPathTitle.includes('%20')) {
          removeSpaces(currentPathTitle);
        } else {
          setTopic(currentPathTitle);
        }
    }
  }, [currentPathTitle]);

  //Get all notifications sorted by date
  const fetchTicketNotifications = useCallback(() => {
    getTicketNotifications(uuid)
      .then((res) => {
        if (!res.data.error) {
          const ticketNotifications = res.data.data;

          //Sort notifications by date
          const sortedNotifications = ticketNotifications
            ?.map((obj) => {
              return { ...obj, date: new Date(obj.created) };
            })
            .sort((a, b) => b.date - a.date);
          setNotifications(sortedNotifications);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [uuid]);

  useEffect(() => {
    fetchTicketNotifications();

    const interval = setInterval(() => {
      fetchTicketNotifications();
    }, 60000);

    return () => clearInterval(interval);
  }, [fetchTicketNotifications]);

  // Count unread notifications
  const unreadNotifications = notifications.filter(
    (notification) => notification.is_read === NOTIFICATION_STATUS.NOT_READ,
  ).length;

  // Mark all notifications as read
  const markAllNotificationsAsRead = () => {
    const body = {
      user_id: uuid,
    };

    readAllNotifications(body)
      .then((res) => {
        if (!res.data.error) {
          fetchTicketNotifications();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // Mark the selected notifications as read and navigate to the ticket page
  const markNotificationsAsRead = (notificationId) => {
    const body = {
      notification_id: notificationId,
    };

    readNotifications(body)
      .then((res) => {
        if (!res.data.error) {
          fetchTicketNotifications();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleNotificationClick = (selectedNotification) => {
    // Redirect to ticket page
    navigate({ pathname: '/ticket/' + selectedNotification.ticket_id });

    // If the notification has not been read, mark it as read
    if (selectedNotification.is_read === NOTIFICATION_STATUS.NOT_READ) {
      markNotificationsAsRead(selectedNotification.id);
    }
  };

  const handleMouseEnter = (value) => {
    setIsHovering(value);
  };

  const handleMouseLeave = (value) => {
    setIsHovering(value);
  };

  return (
    <div className="col top-bar-container">
      <h5 className="top-bar-text">{topic}</h5>

      <div className="top-bar-icon-container">
        <NavDropdown
          title={
            <span>
              {unreadNotifications !== 0 && (
                <div className="notification-badge">{unreadNotifications}</div>
              )}
              <Icon.BellFill size={34} />
            </span>
          }
          id="collasible-nav-dropdown"
          className="top-bar-notification">
          <div className="notification-header">Notifications</div>

          <div className="notification-reader" onClick={markAllNotificationsAsRead}>
            <Icon.CheckLg size={22} />
            <span>Mark all as read</span>
          </div>

          {notifications &&
            notifications.map((notification) => {
              var CurrentDate = moment();
              var ms = moment(CurrentDate).diff(moment(notification.created));
              var duration = moment.duration(ms)._data;

              var years = duration.years;
              var months = duration.months;
              var days = duration.days;
              var hours = duration.hours;
              var minutes = duration.minutes;
              var seconds = duration.seconds;

              return (
                <div
                  key={notification.id}
                  className="row notification-card"
                  onMouseEnter={() => handleMouseEnter(notification.id)}
                  onMouseLeave={() => handleMouseLeave('')}
                  style={{
                    backgroundColor: isHovering === notification.id ? '#0aaeef1a' : '#FFFFFF',
                  }}
                  onClick={() => handleNotificationClick(notification)}>
                  <div className="col-md-2 p-0 m-0 notification-medium-icon">
                    <img src={ParaqumLogoOnly} alt="Logo" className="notification-logo" />
                  </div>

                  <div className="col-md-10 p-0 m-0">
                    <div className="notification-title-container">
                      <div className="notification-title">
                        {notification.notification_type === NOTIFICATION_TYPE.ADDED_COMMENT
                          ? 'You have a new comment'
                          : notification.notification_type === NOTIFICATION_TYPE.UPDATED_TICKET
                          ? 'Your ticket has been updated'
                          : notification.notification_type ===
                            NOTIFICATION_TYPE.UPDATED_TICKET_STATUS
                          ? notification.str1
                          : 'A new ticket has been created'}
                      </div>

                      {notification.is_read === NOTIFICATION_STATUS.NOT_READ ? (
                        <Icon.CircleFill color="#1877F2" />
                      ) : null}
                    </div>

                    <div>{notification.subject}</div>

                    <div className="notification-time">
                      {years !== 0
                        ? months !== 0
                          ? years + ' years ' + months + ' months ago'
                          : years + ' years ago'
                        : months !== 0
                        ? days !== 0
                          ? months + ' months ' + days + ' days ago'
                          : months + ' months ago'
                        : days !== 0
                        ? hours !== 0
                          ? days + ' days ' + hours + ' hours ago'
                          : days + ' days ago'
                        : hours !== 0
                        ? minutes !== 0
                          ? hours + ' hours ' + minutes + ' minutes ago'
                          : hours + ' hours ago'
                        : minutes !== 0
                        ? seconds !== 0
                          ? minutes + ' minutes ' + seconds + ' seconds ago'
                          : minutes + ' minutes ago'
                        : seconds + ' seconds ago'}
                    </div>
                  </div>
                </div>
              );
            })}
        </NavDropdown>

        <Link to="/profile" className="top-bar-profile-link">
          <div>
            <Icon.PersonCircle size={34} />
            <div>{userName}</div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default TopBar;
