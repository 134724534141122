/**
 * File: AuthContext.js
 * Description: This file defines the AuthContext to manage authentication-related data using a custom hook and context provider.
 * Author: John Lesley
 * Created: 18/12/2023
 * Last Modified: 18/12/2023 John Lesley
 */

import React, { createContext, useContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import { LOCAL_STORAGE_TOKEN } from '../constants/localStorageConstants';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [localStorageToken, setLocalStorageToken] = useLocalStorage(
    LOCAL_STORAGE_TOKEN,
    null
  );

  return (
    <AuthContext.Provider
      value={{
        localStorageToken,
        setLocalStorageToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
