/**
 * File: index.js(API End Points)
 * Description: Single Entry Point to Create API calls with backend
 *              Do not create sepearte get,post,put or delete methods to make API calls.
 *              Use only the created GET,POST,PUT and DELETE function for API calls
 * Author: Sindujan
 * Created: 16/2/2023
 * Last Modified: 16/8/2024 Sulakshanee Theja
 */

import { BASE_URL } from '../constants';
import axios from 'axios';

export const GET = async (path, body = {}, type) => {
  const tokenStr = localStorage.getItem('token');

  if (!tokenStr) {
    console.log('NO TOKEN');
    return null;
  }

  body.headers = { Authorization: `Bearer ${tokenStr}` };
  if (type === null) {
    try {
      const res = await axios.get(path, body);
      return res;
    } catch (err) {
      if (
        err.response.status === 403 ||
        err.response.status === 405 ||
        err.response.status === 404
      ) {
        localStorage.clear();
        window.location.reload(false);
        return;
      }
    }
  } else {
    try {
      const resp = await axios.get(path, body, { responseType: type });
      return resp;
    } catch (err) {
      if (
        err.response.status === 403 ||
        err.response.status === 405 ||
        err.response.status === 404
      ) {
        localStorage.clear();
        window.location.reload(false);
        return;
      }
    }
  }
};

export const POST = async (path, body, bypass = false) => {
  if (bypass) {
    return await axios.post(path, body, {});
  } else {
    const tokenStr = localStorage.getItem('token');
    if (!tokenStr) {
      console.log('NO TOKEN');
      return null;
    }

    try {
      const res = await axios.post(path, body, {
        headers: { Authorization: 'Bearer ' + tokenStr },
      });
      return res;
    } catch (err) {
      if (
        err.response.status === 403 ||
        err.response.status === 405 ||
        err.response.status === 404
      ) {
        localStorage.clear();
        window.location.reload(false);
        return;
      }
    }
  }
};

// API End Points Related to Auth-----------------------------------------------------------------------------
export const login = async (email, password) => {
  return await axios.post(BASE_URL + '/login', {
    email,
    pwd: password, //need to change to pwd
  });
};

export const logout = async () => {
  return await POST(BASE_URL + '/logout');
};

// Reset Password
export const sendPasswordResetLink = async (email) => {
  return await axios.post(BASE_URL + '/forgotPassword', {
    email: email,
  });
};

export const checkResetToken = async (body) => {
  return await axios.post(BASE_URL + '/checkResetToken', body);
};

export const resetPassword = async (body) => {
  return await axios.post(BASE_URL + '/resetPasswordbyLink', body);
};

export const verifyResetPasswordURL = async (body) => {
  return await axios.post(BASE_URL + '/resetEligibility', body);
};

// Change Password
export const changePassword = async (body) => {
  return await POST(BASE_URL + '/resetPassword', body);
};

// User Registration
export const register = async (postData) => {
  return await axios.post(BASE_URL + '/register', postData);
};

// API End Points Related to User Profile-----------------------------------------------------------------------------
export const getProfile = async () => {
  return await POST(BASE_URL + '/getProfile', {});
};

// User Contact Details
export const getContacts = async () => {
  return await POST(BASE_URL + '/getContacts', {});
};

export const addContact = async (body) => {
  return await POST(BASE_URL + '/addContact', body);
};

export const updateContact = async (body) => {
  return await POST(BASE_URL + '/updateContact', body);
};

export const deleteContact = async (body) => {
  return await POST(BASE_URL + '/deleteContact', body);
};

// API End Points Related to Products-----------------------------------------------------------------------------
export const getProductsByClientId = async (id) => {
  return await POST(BASE_URL + '/getProductsByClientId', {
    id,
  });
};

export const getAllProducts = async () => {
  return await POST(BASE_URL + '/getAllProducts', {});
};

export const getProductByProductId = async (postData) => {
  return await POST(BASE_URL + '/getProductById', {
    postData,
  });
};

// API End Points Related to License-----------------------------------------------------------------------------
export const getLicensesByClientId = async (id) => {
  return await POST(BASE_URL + '/licensesByUserId', {
    id,
  });
};

export const getLicensesByProductId = async (id) => {
  return await POST(BASE_URL + '/licensesByProductId', {
    id,
  });
};

export const createLicense = async (data) => {
  return await POST(BASE_URL + '/licenses/create', {
    data,
  });
};

// API End Points Related to Tickets-----------------------------------------------------------------------------
export const getTicketsByClientId = async () => {
  return await GET(BASE_URL + '/getTickets');
};

export const getTicketsByTicketId = async (id) => {
  return await GET(BASE_URL + '/ticket/' + id);
};

export const addTicket = async (body) => {
  return await POST(BASE_URL + '/createTicket', body);
};

export const downloadTicketAttachment = async (token) => {
  return await GET(BASE_URL + '/download/' + token, { responseType: 'blob' });
};

export const reopenTicket = async (body) => {
  return await POST(BASE_URL + '/reopenTicket', body);
};

// API End Points Related to Notifications-----------------------------------------------------------------------------
export const getTicketNotifications = async (id) => {
  return await GET(BASE_URL + '/ticketNotifications/' + id);
};

export const readAllNotifications = async (body) => {
  return await POST(BASE_URL + '/readAllNotification', body);
};

export const readNotifications = async (body) => {
  return await POST(BASE_URL + '/readNotification', body);
};

// Ticket Comments
export const addComment = async (data) => {
  return await POST(BASE_URL + '/addComment', data);
};

export const getCommentsByTicketId = async (id) => {
  return await GET(BASE_URL + '/getTicketComments/' + id);
};

// API End Points Related to Devices-----------------------------------------------------------------------------
export const getAllDevices = async () => {
  return await POST(BASE_URL + '/getAllDevices', {});
};

export const getDevicesById = async (body) => {
  return await POST(BASE_URL + '/getDeviceByID', body);
};

export const getSubscriberCounts = async (body) => {
  return await POST(BASE_URL + '/getSubscriberCounts', body);
};

// API End Points Related to Billing-----------------------------------------------------------------------------
export const getBillingHistory = async () => {
  return await POST(BASE_URL + '/getBillingHistory', {});
};

export const downloadBill = async (token) => {
  return await GET(BASE_URL + '/invoice/' + token, {}, 'blob');
};

// API End Points Related to Payment-----------------------------------------------------------------------------
export const confirmPayment = async (body) => {
  return await POST(BASE_URL + '/confirmPayment', body, true);
};
