/**
 * File: index.js(Constants)
 * Description: You can define all the constant value here which are GLOBAL value for whole application.
 * Author: Sindujan
 * Created: 20/2/2023
 * Last Modified: 23/7/2024 Sulakshanee Theja
 */

// Base URL
export const BASE_URL = 'https://portal.paraqum.com/api/client';
// export const BASE_URL = 'http://192.168.1.148:5005/client';

// Device status
export const DEVICE_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
};

// Months
export const MONTH_ARRAY = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// Currency list
export const CURRENCY_LIST = [
  { value: 'USD', lable: 'USD', Symbol: '$' },
  { value: 'EUR', lable: 'EUR', Symbol: '€' },
  { value: 'GBP', lable: 'GBP', Symbol: '£' },
  { value: 'AUD', lable: 'AUD', Symbol: 'A$' },
  { value: 'CAD', lable: 'CAD', Symbol: 'C$' },
];
