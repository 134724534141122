/**
 * File: UserDetails.js
 * Description: This file contains the basic details of user.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 23/7/2024 Sulakshanee Theja
 */

import React from 'react';
import * as Icon from 'react-bootstrap-icons';

function UserDetails({ client }) {
  return (
    <div className="user-details-container">
      <div className="row">
        <div className="col-md-3">
          <Icon.PersonSquare className="user-profile-icon" />
        </div>

        <div className="col-md-4">
          <p className="user-name">{client.name}</p>
          <p className="user-company">{client.company}</p>
        </div>

        <div className="col-md-5">
          <div className="user-details-with-icon">
            <Icon.Envelope size={20} />
            <div className="user-details">{client.email}</div>
          </div>

          <div className="user-details-with-icon">
            <Icon.Telephone size={20} />
            <div className="user-details">{client.phone}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
