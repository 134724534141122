import React from 'react';

// function ReleaseNotesComponent({ product, defaultProduct }) {

//     const [releases, setReleases] = useState([]);

//     console.log(defaultProduct);

//     useEffect(() => {
//         const fetchData = () => {
//             fetch('http://localhost:8000/Releases', {
//                 headers: {
//                     'Content-Type': 'application/json'
//                 }
//             }).then((response) => {
//                 return response.json();
//             }).then(data => {
//                 if (product[0]) {
//                     const releases = data.filter(release => release.name === product[0]);
//                     setReleases(releases);
//                 }else{
//                     const releases = data.filter(release => release.name === defaultProduct[0]);
//                     setReleases(releases);
//                 }

//             })
//         }

//         fetchData();
//     }, [defaultProduct, product])


// const CalculateDays = ({ released_date, status }) => {

//     const currentDate = new Date();
//     const releasedDate = new Date(released_date);
//     const numberOfDays = Math.floor((+currentDate - +releasedDate) / 1000 / 3600 / 24);

//     return (
//         <>
//             {status === 'updated' ?
//                 < p className="fs-6 mb-0" id="notes-updated-text" >Updated {numberOfDays + ' ' + (numberOfDays > 1 ? 'days' : 'day')} ago</p >
//                 : status === 'new' &&
//                 < p className="fs-6 mb-0" id="notes-updated-text" >Released {numberOfDays + ' ' + (numberOfDays > 1 ? 'days' : 'day')} ago</p >
//             }
//         </>
//     )
// }

//     return (
//         <>
//             {releases.map((release, key) => (
//                 <div className="container m-0 mb-3 px-3 py-2" id="notes-container" key={key}>
//                     <div className="fs-6 mb-2" id="notes-version">{release.version}</div>
//                     <div className="flex-row d-flex align-items-center mb-4">
//                         <span className="badge" style={{ backgroundColor: release.status === 'new' ? '#028B0F' : '#FBC21D' }}>
//                             {(release.status).slice(0, 1).toUpperCase() + (release.status).slice(1, release.status.length).toLowerCase()}
//                         </span>
//                         <CalculateDays released_date={release.released_date} status={release.status} />
//                     </div>
//                     <p id="notes-desc">{release.description}</p>
//                 </div >
//             ))

//             }
//         </>
//     )
// }

function ReleaseNotesComponent() {
    return (
        <p>Nooooo</p>
    )
}

export default ReleaseNotesComponent;
