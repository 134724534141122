/**
 * File: Billing.js
 * Description: This file is defined to show the current bill, recent invoices and number of subscribers. Also includes functions for
 *              paying bills and downloading invoices.
 * Author: Nethmi Aththanayake
 * Created: 21/12/2022
 * Last Modified: 23/7/2024 Sulakshanee Theja
 */

import React, { useState, useEffect } from 'react';
import '../../styles/billingStyles.scss';
import SelectedBillDetails from './components/SelectedBillDetails';
import RecentBills from './components/RecentBills';
import SubscriberCountGraph from './components/SubscriberCountGraph';
import { getAllDevices, getBillingHistory } from '../../api';
import * as Icon from 'react-bootstrap-icons';
import { generateMonthArray, getPastYearsWithCurrent } from '../../utils/dateUtils';
import Select from 'react-select';
import { CURRENCY_LIST } from '../../constants';

const Billing = () => {
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [billList, setBillList] = useState([]);
  const [selectedBillList, setSelectedBillList] = useState([]);
  const [selectedBill, setSelectedBill] = useState({});
  const [selectedBillId, setSelectedBillId] = useState();
  const [loading, setLoading] = useState(false);

  const monthOptions = generateMonthArray();

  const yearOptions = getPastYearsWithCurrent(15).map((year) => ({
    value: year,
    label: year,
  }));

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const allDevices = await getAllDevices();
        if (!allDevices.data.err) {
          setDeviceList(allDevices.data.data);
          setSelectedDevice(allDevices.data.data[0]);
        }

        const billingHistory = await getBillingHistory();
        if (!billingHistory.data.err) {
          setBillList(billingHistory.data.data);
          setLoading(false);
        }

        // Set the first element of sorted bill list as the selected bill
        const selectedDeviceBillList = billingHistory.data.data
          .filter((bill) => {
            return bill.device_name === allDevices.data.data[0].device_name;
          })
          .map((bill) => {
            return { ...bill, date: new Date(bill.created) };
          })
          .sort((a, b) => b.date - a.date);

        setSelectedBillList(selectedDeviceBillList);
        setSelectedBill(selectedDeviceBillList[0]);
        setSelectedBillId(selectedDeviceBillList[0].invoice_id);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const handleSelectChange = (type, selectedOption) => {
    switch (type) {
      case 'year':
        setSelectedYear(selectedOption);
        break;
      case 'month':
        setSelectedMonth(selectedOption);
        break;
      default:
        break;
    }
  };

  // Set the sorted bill list and first bill on the selected device
  const handleSelectedDevice = (device) => {
    setSelectedDevice(device);

    const selectedDeviceBillList = billList
      .filter((bill) => {
        return bill.device_name === device.device_name;
      })
      .map((bill) => {
        return { ...bill, date: new Date(bill.updated) };
      })
      .sort((a, b) => b.date - a.date);

    setSelectedBillList(selectedDeviceBillList);
    setSelectedBill(selectedDeviceBillList[0]);
    setSelectedBillId(selectedDeviceBillList[0]?.invoice_id);
  };

  return (
    <div className="m-0 container-fluid main-body-container">
      <ul className="nav nav-pills" role="tablist">
        {deviceList.map((device, index) => {
          return (
            <li
              key={index}
              className={`nav-item  active ${selectedDevice === device ? 'pill' : ''}`}
              role="presentation">
              <button
                onClick={() => handleSelectedDevice(device)}
                className="nav-link active"
                id="pills-general-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-dev1"
                type="button"
                role="tab"
                aria-controls="pills-general"
                aria-selected="true">
                {device.device_name}
              </button>
            </li>
          );
        })}
      </ul>

      <div className="row">
        <div className="col-lg-4">
          {loading === false ? (
            billList.filter((bill) => {
              return bill.device_name === selectedDevice.device_name;
            })[0] ? (
              <SelectedBillDetails
                selectedBill={selectedBill}
                selectedDevicePricingType={selectedDevice.pricing_type}
                selectedDeviceCurrencySymbol={
                  CURRENCY_LIST.filter((currencyObj) => {
                    return currencyObj.value === selectedDevice.currency;
                  })[0]?.Symbol
                }
              />
            ) : (
              <div className="no-data-container">
                <Icon.ExclamationTriangle size={20} />
                <div>No Current Bill Found</div>
              </div>
            )
          ) : (
            <div className="loading-container">
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          )}
        </div>

        <div className="col-lg-8">
          <RecentBills
            recentBills={selectedBillList}
            loading={loading}
            setSelectedBill={setSelectedBill}
            selectedBillId={selectedBillId}
            setSelectedBillId={setSelectedBillId}
            selectedDevicePricingType={selectedDevice.pricing_type}
            selectedDeviceCurrencySymbol={
              CURRENCY_LIST.filter((currencyObj) => {
                return currencyObj.value === selectedDevice.currency;
              })[0]?.Symbol
            }
          />
        </div>
      </div>

      <div className="subscriber-count-graph-container">
        <div className="row">
          <div className="col-md-6 filter-sub-container">
            <div>Select a year : </div>
            <Select
              className="basic-single filter-select"
              classNamePrefix="select"
              value={selectedYear && yearOptions.filter((year) => year.value === selectedYear)[0]}
              defaultValue={''}
              isSearchable={true}
              name="device"
              options={yearOptions}
              onChange={(e) => handleSelectChange('year', e.value)}
            />
          </div>

          <div className="col-md-6 filter-sub-container">
            <div>Select a month : </div>
            <Select
              className="basic-single filter-select"
              classNamePrefix="select"
              value={
                selectedMonth && monthOptions.filter((month) => month.value === selectedMonth)[0]
              }
              defaultValue={''}
              isSearchable={true}
              name="device"
              options={monthOptions}
              onChange={(e) => handleSelectChange('month', e.value)}
            />
          </div>
        </div>

        {selectedDevice && (
          <SubscriberCountGraph
            selectedDevice={selectedDevice}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
          />
        )}
      </div>
    </div>
  );
};

export default Billing;
