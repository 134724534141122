/**
 * File: TicketStatus.js
 * Description: This file contains the ticket status and updated date.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 5/2/2024 Sulakshanee Theja
 */

import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';
import { TICKET_STATUS } from '../../../../constants/ticketConstants';

const TicketStatus = ({ ticketStatuses }) => {
  const [sortedTicketStatuses, setSortedTicketStatuses] = useState([]);

  useEffect(() => {
    // Sorting the ticketStatuses array by created date in ascending order
    const sortedData = ticketStatuses.sort(
      (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime(),
    );
    setSortedTicketStatuses(sortedData);
  }, [ticketStatuses]);

  return (
    <div className="p-0 col-md-5">
      {ticketStatuses.length > 3 ? (
        sortedTicketStatuses &&
        sortedTicketStatuses.map((ticketStatus, index) => (
          <div className="ticket-status-item" key={index}>
            <div className="ticket-status-icon-container">
              <Icon.CheckCircle
                size={20}
                style={{
                  color:
                    ticketStatus.status === TICKET_STATUS.NEW_TICKET
                      ? '#EE6363'
                      : ticketStatus.status === TICKET_STATUS.IN_PROGRESS_TICKET
                      ? '#FBC21D'
                      : '#0B9919',
                }}
              />
              {index < sortedTicketStatuses.length - 1 && (
                <div className="ticket-status-line"></div>
              )}
            </div>

            <div>
              <div className="ticket-status-step">
                {ticketStatus.status === TICKET_STATUS.NEW_TICKET
                  ? 'Created'
                  : ticketStatus.status === TICKET_STATUS.CLOSED_TICKET
                  ? 'Closed'
                  : ticketStatus.status === TICKET_STATUS.IN_PROGRESS_TICKET && index === 1
                  ? 'In Progress'
                  : 'Reopened & In Progress'}
              </div>
              <div className="ticket-status-step-date">
                {moment(ticketStatus?.created).format('LLL')}
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="ticket-status-item">
            <div className="ticket-status-icon-container">
              <Icon.CheckCircle color="#EE6363" size={20} />
              <div className="ticket-status-line"></div>
            </div>

            <div>
              <div className="ticket-status-step">Created</div>
              <div className="ticket-status-step-date">
                {moment(ticketStatuses[0]?.created).format('LLL')}
              </div>
            </div>
          </div>

          <div className="ticket-status-item">
            <div className="ticket-status-icon-container">
              {ticketStatuses[1] !== undefined ? (
                <Icon.CheckCircle color="#FBC21D" size={20} />
              ) : (
                <Icon.Circle color="#FBC21D" size={20} />
              )}
              <div className="ticket-status-line"></div>
            </div>

            <div>
              <div className="ticket-status-step">In Progress</div>
              <div className="ticket-status-step-date">
                {ticketStatuses[1] !== undefined &&
                  moment(ticketStatuses[1]?.created)?.format('LLL')}
              </div>
            </div>
          </div>

          <div className="ticket-status-item">
            <div className="ticket-status-icon-container">
              {ticketStatuses[2] !== undefined ? (
                <Icon.CheckCircle color="#0B9919" size={20} />
              ) : (
                <Icon.Circle color="#0B9919" size={20} />
              )}
            </div>

            <div>
              <div className="ticket-status-step">Closed</div>
              <div className="ticket-status-step-date">
                {ticketStatuses[2] !== undefined &&
                  moment(ticketStatuses[2]?.created)?.format('LLL')}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TicketStatus;
