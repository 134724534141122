/**
 * File: DashboardSubscriptions.js
 * Description: This file contains the status of the client's subscription.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 23/7/2024 Sulakshanee Theja
 */

import React from 'react';
import '../../../styles/dashboard.scss';
import {
  StyledBodyCell,
  StyledBodyRow,
  StyledHeadCell,
} from '../../../components/table/StyledTable';
import { DEVICE_STATUS } from '../../../constants';

function DashboardSubscriptions({ deviceList }) {
  return (
    <div className="subscription-container">
      <p className="dashboard-sub-component-title">Subscriptions</p>

      <div className="subscription-table-container">
        <table className="table table-hover">
          <thead>
            <tr>
              <StyledHeadCell>Devices</StyledHeadCell>
              <StyledHeadCell>Products</StyledHeadCell>
              <StyledHeadCell>Status</StyledHeadCell>
            </tr>
          </thead>

          <tbody>
            {deviceList.map((device, index) => {
              return (
                <StyledBodyRow key={index}>
                  <StyledBodyCell>{device.device_name}</StyledBodyCell>
                  <StyledBodyCell>{device.product}</StyledBodyCell>
                  <StyledBodyCell>
                    <span
                      className={
                        device.active === DEVICE_STATUS.ACTIVE
                          ? 'badge bg-primary'
                          : 'badge bg-danger'
                      }>
                      {device.active === DEVICE_STATUS.ACTIVE ? 'Active' : 'Inactive'}
                    </span>
                  </StyledBodyCell>
                </StyledBodyRow>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DashboardSubscriptions;
