/**
 * File: StyledSideBar.js
 * Description: This component defines styled sidebar elements.
 * Author: Sulakshanee Theja
 * Created: 14/3/2024
 * Last Modified: 14/3/2024 Sulakshanee Theja
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Define styled sidebar elements
export const Nav = styled.div`
  background: #0d2841;
  height: 60px;
  display: none;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;

  @media screen and (max-width: 990px) {
    display: flex;
  }
`;

export const ToggledNavIcon = styled(Link)`
  margin-left: 1rem;
  font-size: 2rem;
  height: 60px;
  display: none;
  align-items: center;
  color: #0aaeef;

  @media screen and (max-width: 990px) {
    display: flex;
  }
`;

export const CloseNavIcon = styled(Link)`
  background: #0d2841;
  font-size: 2rem;
  height: 60px;
  display: none;
  align-items: center;
  width: 16.7%;
  padding-left: 15px;
  color: #0aaeef;

  @media screen and (max-width: 990px) {
    display: flex;
    position: fixed;
    top: 0;
    width: 250px;
  }
`;

export const SidebarNav = styled.nav`
  background: #0d2841;
  width: 16.7%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 350ms;
  overflow-y: auto;

  @media screen and (max-width: 990px) {
    width: 250px;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  }

  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;

export const SidebarWrap = styled.div`
  width: 100%;
`;

export const SidebarHeader = styled.div`
  background: #0d2841;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  position: fixed;
  width: 16.7%;
  top: 0;

  @media screen and (max-width: 990px) {
    top: 60px;
    width: 250px;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

export const SidebarFooter = styled.div`
  background: #0d2841;
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  position: fixed;
  bottom: 0;
  width: 16.7%;
  border-top: 0.1px solid #fff;

  &:hover {
    background: #024b77;
    cursor: pointer;
    color: #fff;
  }

  @media screen and (max-width: 990px) {
    width: 250px;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

export const SidebarContent = styled.div`
  margin-bottom: 60px;
  margin-top: 90px;

  @media screen and (max-width: 990px) {
    margin-top: 150px;
  }
`;

export const SidebarLabel = styled.span`
  margin-left: 16px;
`;

export const SidebarLink = styled(Link)`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    background: #024b77;
    cursor: pointer;
    color: #fff;
    opacity: 0.5;
  }

  background: ${({ position, title }) => (position === title.toLowerCase() ? '#024b77' : null)};
  border-left: ${({ position, title }) =>
    position === title.toLowerCase() ? '4px solid #0aaeef' : null};
`;

export const DropdownLink = styled(Link)`
  background: #33415c;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 15px;

  &:hover {
    background: #024b77;
    cursor: pointer;
    color: #fff;
    opacity: 0.5;
  }

  background: ${({ position, title }) => (position === title.toLowerCase() ? '#024b77' : null)};
  border-left: ${({ position, title }) =>
    position === title.toLowerCase() ? '4px solid #0aaeef' : null};
`;
