/**
 * File: PrivateWrapper.js
 * Description: This file defines a private wrapper component for authenticated routes.
 * Author: Sulakshanee Theja
 * Created: 26/3/2024
 * Last Modified: 27/3/2024 Sulakshanee Theja
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateWrapper = ({ children }) => {
  const location = useLocation();
  const previousLocation = location.pathname;
  const { localStorageToken } = useAuth();

  return localStorageToken ? (
    children
  ) : (
    <Navigate to="/" state={{ previousLocation: previousLocation }} replace />
  );
};

export default PrivateWrapper;
