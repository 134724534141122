/**
 * File: PublicWrapper.js
 * Description: This file defines a public wrapper component for non-authenticated routes.
 * Author: Sulakshanee Theja
 * Created: 26/3/2024
 * Last Modified: 26/3/2024 Sulakshanee Theja
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Navigate } from 'react-router-dom';

const PublicWrapper = ({ children }) => {
  const { localStorageToken } = useAuth();

  return localStorageToken ? <Navigate to="/dashboard" replace /> : children;
};

export default PublicWrapper;
