/**
 * File: ProductOverview.js
 * Description: This file contains the implementation of the ProductOverview component,
 *              which displays a table of devices and allows searching for devices.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 13/2/2024 Sulakshanee Theja
 */

import React, { useState, useEffect, useCallback } from 'react';
import '../../styles/products.scss';
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { getAllDevices } from '../../api';
import moment from 'moment';
import '../../style.scss';
import {
  StyledBodyCell,
  StyledBodyRow,
  StyledHeadCell,
  StyledLoadingBodyRow,
} from '../../components/table/StyledTable';
import { MONTH_ARRAY } from '../../constants';

const ProductOverview = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [deviceList, setDeviceList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(() => {
    getAllDevices()
      .then((res) => {
        const allDevices = res.data.data;
        setDeviceList(allDevices);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [fetchData]);

  const getDifferenceDays = (date1, date2) => {
    var differenceMs = Math.abs(date2 - date1);

    var differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

    return differenceDays;
  };

  const getDateString = (dataString) => {
    let year = new Date(dataString).getFullYear();
    let month = new Date(dataString).getMonth();
    let date = new Date(dataString).getDate();

    return year + '-' + MONTH_ARRAY[month] + '-' + date;
  };

  return (
    <div className="m-0 container-fluid main-body-container">
      <div className="device-table-container">
        <div className="device-search-container">
          <input
            className="form-control"
            type="text"
            placeholder="Search for a Device"
            aria-label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <button className="btn search-button" type="submit" onClick={() => setSearchTerm('')}>
            <Icon.X color="#0D2841" size={22} />
          </button>
        </div>

        <table className={`table ${loading === false ? 'table-hover' : ''}`}>
          <thead>
            <tr>
              <StyledHeadCell>Device Name</StyledHeadCell>
              <StyledHeadCell>Product Name</StyledHeadCell>
              <StyledHeadCell>Purchase Date</StyledHeadCell>
              <StyledHeadCell>Billing Date</StyledHeadCell>
              <StyledHeadCell>Trail Ends On</StyledHeadCell>
              <StyledHeadCell></StyledHeadCell>
            </tr>
          </thead>

          <tbody>
            {loading === false ? (
              deviceList
                .filter((item) => {
                  return item.device_name.toLowerCase().includes(searchTerm.toLowerCase());
                })
                .map((product, index) => (
                  <StyledBodyRow key={index}>
                    <StyledBodyCell>{product.device_name}</StyledBodyCell>
                    <StyledBodyCell>{product.product}</StyledBodyCell>
                    <StyledBodyCell>{moment(product.created).format('DD/MM/YYYY')}</StyledBodyCell>
                    <StyledBodyCell>
                      {product.billing_start_date ? getDateString(product.billing_start_date) : '-'}
                    </StyledBodyCell>
                    <StyledBodyCell>
                      {product.billing_start_date
                        ? getDifferenceDays(new Date(product.billing_start_date), new Date()) +
                          ' Days'
                        : '-'}
                    </StyledBodyCell>
                    <StyledBodyCell>
                      <Link to={`/${product.product}/${product.uuid}`}>
                        <Icon.ArrowRightCircle color="#024b77" size={26} />
                      </Link>
                    </StyledBodyCell>
                  </StyledBodyRow>
                ))
            ) : (
              <StyledLoadingBodyRow>
                <td colSpan={5}>
                  <div className="spinner-border text-primary" role="status"></div>
                </td>
              </StyledLoadingBodyRow>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductOverview;
