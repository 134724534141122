/**
 * File: DeviceDetails.js
 * Description: This file contains the implementation of the DeviceDetails page that displays the details of the selected device.
 * Author: Sulakshanee Theja
 * Created: 9/7/2024
 * Last Modified: 12/7/2024 Sulakshanee Theja
 */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDevicesById } from '../../api';
import { StyledBodyCell, StyledBodyRow } from '../../components/table/StyledTable';
import moment from 'moment';
import * as Icon from '@mui/icons-material';
import { PRICING_TYPE } from '../../constants/deviceConstants';

function DeviceDetails() {
  const params = useParams();
  const [deviceDetails, setDeviceDetails] = useState([]);

  useEffect(() => {
    const body = { id: params.id };

    getDevicesById(body)
      .then((res) => {
        setDeviceDetails(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);

  const deviceDetailsArray = [
    {
      icon: <Icon.DnsOutlined />,
      lable: 'Device Id',
      value: deviceDetails.uuid,
    },
    {
      icon: <Icon.Category />,
      lable: 'Product Name',
      value: deviceDetails.product,
    },
    {
      icon: <Icon.Payment />,
      lable: 'Pricing Type',
      value: (
        <span
          style={{
            backgroundColor: '#ECF5FB',
            color: '#0078D4',
            padding: '5px 10px',
            borderRadius: '5px',
          }}>
          {deviceDetails.pricing_type === PRICING_TYPE.PAY_AS_YOU_GO
            ? 'Pay As You Go'
            : 'Fixed Amount'}
        </span>
      ),
    },
    {
      icon: <Icon.CalendarMonth />,
      lable: 'Purchased Date',
      value: moment(deviceDetails.created).format('DD/MM/YYYY'),
    },
  ];

  return (
    <div className="m-0 container-fluid main-body-container">
      <div className="device-details-container">
        <div className="device-details-header">
          <div className="device-details-title">Device Details - Insights</div>
          <span className="support-tag">24h Support</span>
        </div>

        <table className="table">
          <tbody>
            {deviceDetailsArray.map((device, index) => (
              <StyledBodyRow key={index}>
                <StyledBodyCell className="col-6 detail-label-container">
                  <div className="device-detail-label">
                    {device.icon} {device.lable}
                  </div>
                </StyledBodyCell>
                <StyledBodyCell className="col-6 device-detail-value">
                  {device.value}
                </StyledBodyCell>
              </StyledBodyRow>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DeviceDetails;
