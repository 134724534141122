/**
 * File: CommentSection.js
 * Description: This component displays all comments related to the ticket
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 23/7/2024 Sulakshanee Theja
 */

import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import '../../../../styles/ticketComments.scss';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import ParaqumLogoOnly from '../../../../assets/logos/paraqumLogoOnly.png';
import { CREATED_BY } from '../../../../constants/ticketConstants';

const CommentSection = ({
  ticketComments,
  downloadAttachment,
  loading,
  selectedAttachment,
  clientDetails,
}) => {
  const [showFullDescription, setFullDescription] = useState(false);

  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  return (
    <div className="ticket_comments-container">
      {ticketComments &&
        ticketComments.map((comment) => (
          <Card className="ticket_comment" key={comment.id}>
            <div className="row comment-head-container">
              {comment.created_by_paraqum === CREATED_BY.PARAQUM ? (
                <div className="col-md-6 comment-title-container">
                  <img src={ParaqumLogoOnly} alt="Logo" className="comment-logo" />
                  <Typography className="comment-title">Paraqum</Typography>
                </div>
              ) : (
                <div className="col-md-6 comment-title-container">
                  <Icon.PersonCircle size={20} />
                  <Typography className="comment-title">{clientDetails.name}</Typography>
                </div>
              )}

              <div className="col-md-6 comment-date-container">
                <Typography className="comment-date">
                  {moment(comment?.created).format('LLLL')}
                </Typography>
              </div>
            </div>

            <div className="comment-body-container">
              <div className="comment-content">
                {showFullDescription ? comment.content : comment.content.slice(0, 200)}
                {comment.content.length > 200 ? (
                  <span onClick={showFullDescriptionHandler} className="comment-description-span">
                    {showFullDescription ? ' read less' : '... read more'}
                  </span>
                ) : null}
              </div>

              <div className="comment-attachments">
                {comment.attachments &&
                  comment.attachments.map((attachment) => (
                    <button
                      key={attachment.id}
                      type="button"
                      className="btn btn-outline-secondary btn-sm me-2"
                      onClick={() => downloadAttachment(attachment, attachment.id)}>
                      {loading && selectedAttachment === attachment.id ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Icon.Paperclip color="#0aaeef" size={20} />
                      )}
                    </button>
                  ))}
              </div>
            </div>
          </Card>
        ))}
    </div>
  );
};

export default CommentSection;
