/**
 * File: StyledTable.js
 * Description: This component defines styled table elements.
 * Author: Sulakshanee Theja
 * Created: 30/1/2024
 * Last Modified: 4/2/2024 Sulakshanee Theja
 */

import styled from 'styled-components';

// Define styled table component
export const StyledHeadCell = styled.th`
  font-size: 18px;
`;

export const StyledBodyRow = styled.tr`
  height: 40px;
`;

export const StyledBodyCell = styled.td`
  &&& {
    font-weight: 500;
    font-size: 16px;
    vertical-align: middle;
    color: #505661;
  }
`;

export const StyledLoadingBodyRow = styled.tr`
  height: 56vh;
  vertical-align: middle;
  text-align: center;
`;
