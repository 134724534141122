/**
 * File: Login.js
 * Description: This file contains the login component responsible for handling user authentication.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 30/4/2024 Sulakshanee Theja
 */

import React, { useState } from 'react';
import ParaqumLogo from '../../assets/logos/paraqumLogo.jpg';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../styles/auth.scss';
import * as Icon from 'react-bootstrap-icons';
import { login } from '../../api';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { PASSWORD_TYPE } from '../../constants/authConstants';
import { HTTP_STATUS_NO_CONTENT } from '../../constants/httpStatusCodeConstants';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const { setLocalStorageToken } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [passwordType, setPasswordType] = useState(PASSWORD_TYPE.PASSWORD);
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState();

  const validateLogin = () => {
    let errors = {};

    if (!email) {
      errors.email = 'Email required';
    }

    if (!password) {
      errors.password = 'Password required';
    }

    return errors;
  };

  const handleLogin = (e) => {
    setLoginError('');
    e.preventDefault();
    const isValid = validateLogin();

    if (isValid.email || isValid.password) {
      setErrors(isValid);
    } else {
      setErrors('');

      login(email, password)
        .then((res) => {
          const loginResponse = res.data;

          if (res.status === HTTP_STATUS_NO_CONTENT) {
            Swal.fire({
              icon: 'warning',
              text: 'Account Verification Failed. Check your Email !',
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
          }

          if (loginResponse.message === 'Login successful') {
            localStorage.clear();
            setLocalStorageToken(loginResponse.token);
            localStorage.setItem('userName', loginResponse.name);
            localStorage.setItem('uuid', loginResponse.uuid);

            if (locationState) {
              navigate(locationState.previousLocation);
            } else {
              navigate('/dashboard');
            }
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message === 'Deactivated account') {
            Swal.fire({
              icon: 'warning',
              text: 'Unauthorized User !',
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
          } else if (err?.response?.data?.message === 'Login unsuccessful') {
            Swal.fire({
              icon: 'warning',
              text: 'Invalid Credential !',
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
          } else {
            Swal.fire({
              icon: 'warning',
              text: 'Unsuccesful Login!',
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
          }
        });
    }
  };

  return (
    <div className="p-0 m-0 row container-fluid auth-main-container">
      <div className="p-0 m-0 col-md-6 logo-container">
        <img src={ParaqumLogo} alt="Paraqum Logo" className="logo-image" />
      </div>

      <div className="p-0 m-0 col-md-6 auth-form-container">
        <div className="auth-form-sub-container">
          <div className="header-text">Welcome !</div>

          {/* Login form */}
          <div className="auth-form">
            <div className="auth-form-field-container">
              <label className="auth-form-label" htmlFor="email">
                Email Address
              </label>
              <div className="auth-form-field">
                <input
                  type="email"
                  className="auth-field-input"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrors({ ...errors, email: '' });
                    setLoginError();
                  }}
                />
              </div>
              {errors.email && <p className="auth-field-error">{errors.email}</p>}
            </div>

            <div className="auth-form-field-container">
              <label className="auth-form-label" htmlFor="password">
                Password
              </label>
              <div className="auth-form-field">
                <input
                  type={passwordType}
                  className="auth-field-input"
                  placeholder="********"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrors({ ...errors, password: '' });
                    setLoginError();
                  }}
                />
                {hidePassword ? (
                  <Icon.EyeSlash
                    color="#8893a0"
                    size={20}
                    onClick={() => {
                      setHidePassword(false);
                      setPasswordType(PASSWORD_TYPE.TEXT);
                    }}
                  />
                ) : (
                  <Icon.Eye
                    color="#8893a0"
                    size={20}
                    onClick={() => {
                      setHidePassword(true);
                      setPasswordType(PASSWORD_TYPE.PASSWORD);
                    }}
                  />
                )}
              </div>
              {errors.password && <p className="auth-field-error">{errors.password}</p>}
            </div>

            <Link to="/forgot-password">
              <div className="fogot-password">Forgot Password</div>
            </Link>

            {loginError && <p className="mt-4 auth-field-error">{loginError}</p>}

            <button className="auth-form-button" onClick={handleLogin}>
              Login
            </button>

            <div className="redirect-container">
              <div>Don&apos;t have an account?</div>
              <div className="redirect-text" onClick={() => navigate('/registration')}>
                Create Account
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
