import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

function SubscriberCount() {

    const [labels, setLabels] = useState([]);
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        createData();
    }, [])

    const createData = () => {
        let arr = [];
        const month = 'Nov';
        for (let i = 1; i < 31; i++) {
            arr.push(month + '/' + i);
        }
        setLabels(arr);

        let arr2 = [];
        for (let i = 1; i < 32; i++) {
            arr2.push(Math.floor(Math.random() * 100));
        }
        setGraphData(arr2);
    }


    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Subscriber Count',
                data: graphData,
                fill: false,
                backgroundColor: '#0472CC',
                borderColor: '#0472CC',
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return (
        <div className="container m-0 px-0 py-2">
            <Line
                data={data}
                options={options}
                width={100}
                height={25}
            />
        </div>
    )
}

export default SubscriberCount;
