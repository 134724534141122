/**
 * File: Registration.js
 * Description: This file contains a component for registering a new client to the system.
 *              It provides a form to input client details such as name, email, company, contact person, address, ect.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 26/3/2024 Sulakshanee Theja
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select, { createFilter } from 'react-select';
import { register } from '../../api';
import Swal from 'sweetalert2';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as Icon from 'react-bootstrap-icons';
import { DEFAULT_STATE, DEFAULT_ZIP_CODE } from '../../constants/clientConstants';
import ParaqumLogo from '../../assets/logos/paraqumLogo.jpg';
import '../../styles/auth.scss';
import { PASSWORD_TYPE } from '../../constants/authConstants';

const Registration = () => {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      borderRadius: 8,
      marginTop: 5,
      backgroundColor: '#FFFFFF',
      fontWeight: 400,
      fontSize: 18,
      border: 0,
      outline: 0,
    }),
    option: (base) => ({
      ...base,
      color: '#000000',
    }),
  };

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [company, setCompany] = useState('');
  const [remarks, setRemarks] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [passwordType, setPasswordType] = useState(PASSWORD_TYPE.PASSWORD);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(PASSWORD_TYPE.PASSWORD);
  const [countryList, setCountryList] = useState([]);
  const [errors, setErrors] = useState({});

  const getCountryList = async () => {
    const response = await axios.get('https://restcountries.com/v3.1/all');

    let tempCountryList = [];

    for (let i = 0; i < response.data.length; i++) {
      tempCountryList.push({
        value: response.data[i].name.common,
        label: response.data[i].name.common,
        code: response.data[i].cca2,
      });
    }
    setCountryList(tempCountryList);
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: (option) => `${option.label} ${option.value} ${option.data.code}`,
    trim: true,
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const validateRegistration = () => {
    let errors = {};

    if (!name) {
      errors.name = 'Name required';
    }

    if (!mobile) {
      errors.mobile = 'Mobile number required';
    }

    if (!company) {
      errors.company = 'Company required';
    }

    if (!country) {
      errors.country = 'Country required';
    }

    if (!contactPerson) {
      errors.contactPerson = 'Contact person required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(contactPerson)) {
      errors.contactPerson = 'Email of contact person is invalid';
    }

    if (!email) {
      errors.email = 'Email required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      errors.email = 'Email is invalid';
    }

    if (!confirmEmail) {
      errors.confirmEmail = 'Confirm email required';
    }

    if (!password) {
      errors.password = 'Password required';
    } else if (password.length < 8 || !/[A-Z]+/.test(password) || !/[a-z]+/.test(password)) {
      errors.password = 'Minimum 8 characters with atleast one uppercase & lowercase character';
    }

    if (!confirmPassword) {
      errors.confirmPassword = 'Confirm password required';
    }

    if (!addressLine1) {
      errors.addressLine1 = 'Address line 1 required';
    }
    if (!addressLine2) {
      errors.addressLine2 = 'Address line 2 required';
    }
    if (!addressLine3) {
      errors.addressLine3 = 'Address line 3 required';
    }

    if (zipCode && !/^\d*$/.test(zipCode)) {
      errors.zipCode = 'Zip Code must be a number';
    }

    return errors;
  };

  const renderTooltipForPassword = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Minimum 8 characters with atleast one uppercase & lowercase character
    </Tooltip>
  );

  const renderTooltipForContactPerson = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Email of contact person
    </Tooltip>
  );

  const clientRegistration = (e) => {
    e.preventDefault();
    const isValid = validateRegistration();

    if (
      isValid.name ||
      isValid.mobile ||
      isValid.company ||
      isValid.remarks ||
      isValid.country ||
      isValid.contactPerson ||
      isValid.email ||
      isValid.confirmEmail ||
      isValid.password ||
      isValid.confirmPassword ||
      isValid.addressLine1 ||
      isValid.addressLine2 ||
      isValid.addressLine3 ||
      isValid.zipCode
    ) {
      setErrors(isValid);
    } else {
      if (password === confirmPassword && email === confirmEmail) {
        setErrors('');

        const body = {
          name: name,
          phone: mobile,
          company: company,
          remarks: remarks,
          country: country,
          state: state !== '' ? state : DEFAULT_STATE,
          zip_code: zipCode !== '' ? zipCode : DEFAULT_ZIP_CODE,
          contact_person: contactPerson,
          email: email,
          confirm_email: confirmEmail,
          pwd: password,
          confirm_pwd: confirmPassword,
          addr_1: addressLine1,
          addr_2: addressLine2,
          addr_3: addressLine3,
        };

        register(body)
          .then((res) => {
            if (!res.data.error) {
              Swal.fire({
                icon: 'success',
                text: 'Account Created Successfully. Verification Mail Sent !',
                timer: 3000,
                confirmButtonColor: '#024b77',
              });
              navigate('/');
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              text: err.response.data.message,
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
          });
      } else {
        if (password !== confirmPassword) {
          Swal.fire({
            icon: 'error',
            text: 'Password mismatch. Check your Confirmation Password',
            timer: 3000,
            confirmButtonColor: '#024b77',
          });
        }

        if (email !== confirmEmail) {
          Swal.fire({
            icon: 'error',
            text: 'Email mismatch. Check your Confirmation Email',
            timer: 3000,
            confirmButtonColor: '#024b77',
          });
        }
      }
    }
  };

  return (
    <div className="p-0 m-0 row container-fluid auth-main-container">
      <div className="p-0 m-0 col-md-6 logo-container">
        <img src={ParaqumLogo} alt="Paraqum Logo" className="logo-image" />
      </div>

      <div className="p-0 m-0 col-md-6 auth-form-container">
        <div className="registration-container">
          <div className="header-text">Registration</div>

          {/* Registration form */}
          <div className="row auth-form">
            <div className="col-md-6">
              <div className="auth-form-field-container">
                <label className="auth-form-label">Name *</label>
                <div className="auth-form-field">
                  <input
                    type="text"
                    className="auth-field-input"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrors({ ...errors, name: '' });
                    }}
                  />
                </div>
                {errors.name && <p className="auth-field-error">{errors.name}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Mobile *</label>
                <div className="auth-form-field">
                  <input
                    type="text"
                    className="auth-field-input"
                    value={mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                      setErrors({ ...errors, mobile: '' });
                    }}
                  />
                </div>
                {errors.mobile && <p className="auth-field-error">{errors.mobile}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Company (ISP) *</label>
                <div className="auth-form-field">
                  <input
                    type="text"
                    className="auth-field-input"
                    value={company}
                    onChange={(e) => {
                      setCompany(e.target.value);
                      setErrors({ ...errors, company: '' });
                    }}
                  />
                </div>
                {errors.company && <p className="auth-field-error">{errors.company}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Remarks </label>
                <div className="auth-form-field">
                  <input
                    type="text"
                    className="auth-field-input"
                    value={remarks}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                      setErrors({ ...errors, remarks: '' });
                    }}
                  />
                </div>
                {errors.remarks && <p className="auth-field-error">{errors.remarks}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Primary Email *</label>
                <div className="auth-form-field">
                  <input
                    type="email"
                    className="auth-field-input"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors({ ...errors, email: '' });
                    }}
                  />
                </div>
                {errors.email && <p className="auth-field-error">{errors.email}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Confirm Primary Email *</label>
                <div className="auth-form-field">
                  <input
                    type="email"
                    className="auth-field-input"
                    value={confirmEmail}
                    onChange={(e) => {
                      setConfirmEmail(e.target.value);
                      setErrors({ ...errors, confirmEmail: '' });
                    }}
                  />
                </div>
                {errors.confirmEmail && <p className="auth-field-error">{errors.confirmEmail}</p>}
              </div>

              <div className="auth-form-field-container">
                <div className="auth-form-label-container">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipForPassword}>
                    <Icon.InfoCircle size={20}></Icon.InfoCircle>
                  </OverlayTrigger>
                  <label className="auth-form-label">Password *</label>
                </div>
                <div className="auth-form-field">
                  <input
                    type={passwordType}
                    className="auth-field-input"
                    placeholder="********"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrors({ ...errors, password: '' });
                    }}
                  />
                  {hidePassword ? (
                    <Icon.EyeSlash
                      color="#8893a0"
                      size={20}
                      onClick={() => {
                        setHidePassword(false);
                        setPasswordType(PASSWORD_TYPE.TEXT);
                      }}
                    />
                  ) : (
                    <Icon.Eye
                      color="#8893a0"
                      size={20}
                      onClick={() => {
                        setHidePassword(true);
                        setPasswordType(PASSWORD_TYPE.PASSWORD);
                      }}
                    />
                  )}
                </div>
                {errors.password && <p className="auth-field-error">{errors.password}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Confirm Password *</label>
                <div className="auth-form-field">
                  <input
                    type={confirmPasswordType}
                    className="auth-field-input"
                    placeholder="********"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setErrors({ ...errors, confirmPassword: '' });
                    }}
                  />
                  {hideConfirmPassword ? (
                    <Icon.EyeSlash
                      color="#8893a0"
                      size={20}
                      onClick={() => {
                        setHideConfirmPassword(false);
                        setConfirmPasswordType(PASSWORD_TYPE.TEXT);
                      }}
                    />
                  ) : (
                    <Icon.Eye
                      color="#8893a0"
                      size={20}
                      onClick={() => {
                        setHideConfirmPassword(true);
                        setConfirmPasswordType(PASSWORD_TYPE.PASSWORD);
                      }}
                    />
                  )}
                </div>
                {errors.confirmPassword && (
                  <p className="auth-field-error">{errors.confirmPassword}</p>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="auth-form-field-container">
                <div className="auth-form-label-container">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipForContactPerson}>
                    <Icon.InfoCircle size={20}></Icon.InfoCircle>
                  </OverlayTrigger>
                  <label className="auth-form-label">Contact Person *</label>
                </div>
                <div className="auth-form-field">
                  <input
                    type="email"
                    className="auth-field-input"
                    value={contactPerson}
                    onChange={(e) => {
                      setContactPerson(e.target.value);
                      setErrors({ ...errors, contactPerson: '' });
                    }}
                  />
                </div>
                {errors.contactPerson && <p className="auth-field-error">{errors.contactPerson}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Address Line 1 *</label>
                <div className="auth-form-field">
                  <input
                    type="text"
                    className="auth-field-input"
                    value={addressLine1}
                    onChange={(e) => {
                      setErrors({ ...errors, addressLine1: '' });
                      setAddressLine1(e.target.value);
                    }}
                  />
                </div>
                {errors.addressLine1 && <p className="auth-field-error">{errors.addressLine1}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Address Line 2 *</label>
                <div className="auth-form-field">
                  <input
                    type="text"
                    className="auth-field-input"
                    value={addressLine2}
                    onChange={(e) => {
                      setErrors({ ...errors, addressLine2: '' });
                      setAddressLine2(e.target.value);
                    }}
                  />
                </div>
                {errors.addressLine2 && <p className="auth-field-error">{errors.addressLine2}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Address Line 3 *</label>
                <div className="auth-form-field">
                  <input
                    type="text"
                    className="auth-field-input"
                    value={addressLine3}
                    onChange={(e) => {
                      setErrors({ ...errors, addressLine3: '' });
                      setAddressLine3(e.target.value);
                    }}
                  />
                </div>
                {errors.addressLine3 && <p className="auth-field-error">{errors.addressLine3}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">State</label>
                <div className="auth-form-field">
                  <input
                    type="text"
                    className="auth-field-input"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Country *</label>
                <Select
                  value={countryList.filter((item) => {
                    return item.label === country;
                  })}
                  onChange={(e) => {
                    setCountry(e.label);
                    setErrors({ ...errors, country: '' });
                  }}
                  isSearchable={true}
                  options={countryList}
                  styles={customStyles}
                  filterOption={createFilter(filterConfig)}
                />
                {errors.country && <p className="auth-field-error">{errors.country}</p>}
              </div>

              <div className="auth-form-field-container">
                <label className="auth-form-label">Zip Code</label>
                <div className="auth-form-field">
                  <input
                    type="text"
                    className="auth-field-input"
                    value={zipCode}
                    onChange={(e) => {
                      setZipCode(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="auth-form-button-container">
              <button className="auth-form-button register-button" onClick={clientRegistration}>
                Register
              </button>
            </div>
          </div>

          <div className="redirect-container">
            <div>Already have an account?</div>
            <p className="redirect-text" onClick={() => navigate('/')}>
              Login
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
