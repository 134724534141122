/**
 * File: index.js(payments)
 * Description: This file is responsible for displaying the payment confirmation message.
 * Author: Choxmi Sathsara
 * Created: 22/8/2023
 * Last Modified: 23/7/2024 Sulakshanee Theja
 */

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { confirmPayment } from '../../api';
import { HTTP_STATUS_OK } from '../../constants/httpStatusCodeConstants';
import '../../styles/payments.scss';
import { PAYMENT_CONFIRMATION } from '../../constants/paymentConstants';
import ParaqumLogoOnly from '../../assets/logos/paraqumLogoOnly.png';
import * as Icon from 'react-bootstrap-icons';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function PaymentConfirmation() {
  let { token } = useParams();

  const [status, setStatus] = useState(PAYMENT_CONFIRMATION.PROCESSING);
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 100) {
          clearInterval(progressInterval);
          return prevProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(prevProgress + diff, 100);
      });
    }, 500);

    if (token) {
      confirmPayment({ token })
        .then((res) => {
          if (res && res.data && res.data.status == HTTP_STATUS_OK) {
            setStatus(PAYMENT_CONFIRMATION.DONE);
          } else {
            setStatus(PAYMENT_CONFIRMATION.ERROR);
          }
        })
        .catch(() => {
          setStatus(PAYMENT_CONFIRMATION.ERROR);
        });
    } else {
      setStatus(PAYMENT_CONFIRMATION.ERROR);
    }
  }, [token]);

  return (
    <div className="payment-confirmation-container">
      {status !== PAYMENT_CONFIRMATION.PROCESSING && progress === 100 ? (
        // Render the following block if the status is not processing and progress is at 100%
        <>
          {status === PAYMENT_CONFIRMATION.DONE ? (
            <Icon.CheckCircleFill size={80} color="#15AE89" />
          ) : (
            <Icon.XCircleFill size={80} color="#C13636" />
          )}

          <p
            className="payment-response-head"
            style={{
              color: status === PAYMENT_CONFIRMATION.DONE ? '#15AE89' : '#C13636',
            }}>
            {status === PAYMENT_CONFIRMATION.DONE ? 'Success!' : 'Error!'}
          </p>

          <p className="payment-response-body">
            {status === PAYMENT_CONFIRMATION.DONE
              ? 'Congratulations! Your payment has been processed successfully.'
              : 'We are Sorry! Your payment could not be processed at this time.'}
          </p>

          <Link
            to="/"
            className="portal-redirect-button"
            style={{
              backgroundColor: status === PAYMENT_CONFIRMATION.DONE ? '#15AE89' : '#440000',
            }}>
            <Icon.ChevronLeft color="#FFFFFF" size={18} />
            <div>TAC PORTAL LOGIN</div>
          </Link>
        </>
      ) : (
        // If the status is processing or progress is not yet at 100%, show a loading state
        <>
          <img className="payment-paraqum-logo" src={ParaqumLogoOnly} alt="Paraqum Logo" />

          <Box sx={{ width: '46%' }}>
            <LinearProgress
              className="linear-progress-bar"
              variant="determinate"
              value={progress}
            />
          </Box>

          <p className="loading-head">Loading...</p>

          <p className="payment-response-body">
            Please keep the page open until the loading is complete. Thank you!
          </p>
        </>
      )}
    </div>
  );
}

export default PaymentConfirmation;
