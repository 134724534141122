/**
 * File: ticketConstants.js
 * Description: Ticket related constants
 * Author: Sulakshanee Thjea
 * Created: 25/8/2023
 * Last Modified: 5/2/2024 Sulakshanee Thjea
 */

// Create ticket steps
export const CREATE_TICKET = {
  STEP_0: 0,
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
};

// Ticket type
export const TICKET_TYPE = {
  GENERIC_TICKET: 1,
  ON_A_PRODUCT_TICKET: 2,
};

// Ticket generic type
export const TICKET_GENERIC_TYPE = {
  SOFTWARE_ISSUE: 1,
  HARDWARE_ISSUE: 2,
  OTHER: 3,
};

// Ticket status
export const TICKET_STATUS = {
  NEW_TICKET: 0,
  IN_PROGRESS_TICKET: 1,
  CLOSED_TICKET: 2,
};

// Ticket Priority
export const TICKET_PRIORITY = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};

// Ticket or comment created by
export const CREATED_BY = {
  PARAQUM: 1,
  CLIENT: 0,
};
