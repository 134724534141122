/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import '../../styles/dashboard.scss';
import api from '../../axiosContact';

function LicenseCarousel() {

    const [licenses, setLicenses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api.get('/clients/licensesByUserId', {
                    headers: { 'access-token': localStorage.getItem('token') }
                })
                setLicenses(res.data.data);

            } catch (error) {
                console.log(error);
            }
        }
        // fetchData();
    }, [])

    return (
        <div
            id="licencesCarousel"
            className="carousel carousel-dark slide mb-5 mt-0 p-0"
            data-bs-ride="carousel"
            data-bs-pause="hover"
        >
            {/* carousel inner container */}
            <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="4000">
                    <div className="carousel-caption d-md-block">
                        <h5 className="fw-bold" style={{ color: '#E56B6F' }}>
                            {licenses[0]}
                        </h5>
                        {licenses[0] > 1 ? (
                            <p style={{ color: '#666666' }}>Expire in this week</p>
                        ) : (
                            <p style={{ color: '#666666' }}>Expires in this week</p>
                        )}
                    </div>
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                    <div className="carousel-caption d-md-block">
                        <h5 className="fw-bold" style={{ color: '#FBC21D' }}>
                            {licenses[1]}
                        </h5>
                        {licenses[1] > 1 ? (
                            <p style={{ color: '#666666' }}>Expire in next 30 days</p>
                        ) : (
                            <p style={{ color: '#666666' }}>Expires in next 30 days</p>
                        )}
                    </div>
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                    <div className="carousel-caption d-md-block">
                        <h5 className="fw-bold" style={{ color: '#0B9919' }}>
                            {licenses[2]}
                        </h5>
                        {licenses[2] > 1 ? (
                            <p style={{ color: '#666666' }}>Expire in next 60 days</p>
                        ) : (
                            <p style={{ color: '#666666' }}>Expires in next 60 days</p>
                        )}
                    </div>
                </div>
            </div>

            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#licencesCarousel"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden" style={{ color: 'black' }}>
                    Previous
                </span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#licencesCarousel"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default LicenseCarousel;
