import React, { useEffect, useState } from 'react';

function BillingDetails({ id }) {

    const [product, setProduct] = useState([]);
    // console.log(product);

    useEffect(() => {
        const fetchData = () => {
            fetch(`http://localhost:8000/Products/${id}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                return response.json();
            }).then(data => {
                setProduct(data);
            },
            (error) => {
                console.log(error);
            })
        }

        fetchData();

    }, [id])

    const NextPayment = ({ paymentDate }) => {
        // console.log(paymentDate);

        const currentDate = new Date();
        const NextPayment = new Date(paymentDate);
        const numberOfDays = Math.floor((+NextPayment - +currentDate) / 1000 / 3600 / 24);
        // const numberOfDays = 0;
        return (
            <>
                {numberOfDays > 0 ?
                    <p style={{ color: '#666666' }}>Next payment in <span style={{ color: '#0472CC' }}>{numberOfDays} days </span></p>
                    :
                    <div className="d-flex flex-row justify-content-end">
                        <button type="button" className="btn" style={{ backgroundColor: '#0472CC', color: '#fff' }}>Pay Now</button>
                    </div>
                }
            </>
        )
    }

    return (
        <div className="container m-0 mt-5" id="billing-details-container">
            <div className="row m-0 mb-4">
                <div className="col-md-4">
                    <p className="fs-6 mb-0" id="billing-top-text">Product Name</p>
                    <p className="fs-5" id="billing-detail-text">{product.name}</p>
                </div>
                <div className="col-md-4">
                    <div id="center-col">
                        <p className="fs-6 mb-0" id="billing-top-text">Payment Due On</p>
                        <p className="fs-5" id="billing-detail-text">{product.payment_date}</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div id="right-most-col">
                        <p className="fs-6 mb-0" id="billing-top-text">Last Bill Payment On</p>
                        <p className="fs-5" id="billing-detail-text">{product.last_bill}</p>
                    </div>
                </div>
            </div>
            <div className="row m-0 mb-4">
                <div className="col-md-4">
                    <p className="fs-6 mb-0" id="billing-top-text">Registered To</p>
                    <p className="fs-5" id="billing-detail-text">{product.registered_to}</p>
                </div>
                <div className="col-md-4">
                    <div id="center-col">
                        <p className="fs-6 mb-0" id="billing-top-text">Billing Date</p>
                        <p className="fs-5" id="billing-detail-text">{product.payment_date}</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div id="right-most-col">
                        <p className="fs-6 mb-0" id="billing-top-text">Subscriber Count</p>
                        <p className="fs-5" id="billing-detail-text">{product.subscriber_count}</p>
                    </div>
                </div>
            </div>
            <div className="row m-0 mb-4">
                <div className="col-md-4">
                    <p className="fs-6 mb-0" id="billing-top-text">Registered Year</p>
                    <p className="fs-5" id="billing-detail-text">{product.registered_year}</p>
                </div>
                <div className="col-md-4">
                    <div id="center-col">
                        <p className="fs-6 mb-0" id="billing-top-text">Current Bill</p>
                        <p className="fs-5" id="billing-detail-text">$ {product.current_bill}</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div id="right-most-col">
                        <p className="fs-6 mb-0" id="billing-top-text">Subscription Status</p>
                        {/* <p className="fs-5" id="billing-detail-text">
                            {(product.s_status).slice(0, 1).toUpperCase() + (product.s_status).slice(1, product.s_status.length).toLowerCase()}
                        </p> */}
                        <p className="fs-5" id="billing-detail-text">{product.s_status}</p>
                    </div>
                </div>
            </div>
            <div className="container px-2 mb-5">
                <NextPayment paymentDate={product.payment_date} />
            </div>
        </div >
    )
}

export default BillingDetails;
