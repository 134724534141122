import React, { useState, useEffect } from 'react';
import ReleaseNotesComponent from '../../components/updates/releaseNotesComponent';
import '../../styles/updates.scss';
import HistoryComponent from '../../components/updates/historyComponent';
import api from '../../axiosContact';

function Updates() {



    const [products, setProducts] = useState([]);
    const [productId, setproductId] = useState('');

    useEffect(() => {

        const getProfileDetails = () => {
            api.get('/client/getClient', {
                headers: {
                    'access-token': localStorage.getItem('token'),
                }
            })
                .then((res) => {
                    if (!res.data.error) {
                        setProducts(res.data.data.products);
                    } else {
                        console.log(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });

        }

        getProfileDetails();

    }, [])

    // const handleSelect = useCallback(
    //     (id) => {
    //         const selectedProduct = products.filter(product => product._id === id);
    //         setSelectedProduct([selectedProduct[0]?.name, selectedProduct[0]?.type]);
    //     },
    //     [products],
    // )

    return (
        <>
            <div className="container m-0 mt-4">
                <select className="form-select" onChange={(e) => setproductId(e.target.value)}>
                    {/* {location?.state?.id ?
                        <option value={location?.state?.id}>{location.state.id}</option>

                        :  */}
                    {products.map((product, key) => (
                        <option key={key} value={product._id}>{product.productName}</option>
                    ))
                    }
                    {/* } */}
                </select>
            </div>
            <div className="container mx-0 mt-4">
                <div className="container py-4 px-2 updates-main-container">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="pills-updates-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-updates"
                                type="button"
                                role="tab"
                                aria-controls="pills-updates"
                                aria-selected="false"
                            >History</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-general-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-general"
                                type="button"
                                role="tab"
                                aria-controls="pills-general"
                                aria-selected="true"
                            >Release Notes</button>
                        </li>
                    </ul>

                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade " id="pills-general" role="tabpanel" aria-labelledby="pills-general-tab">
                            <div className="container mt-4 px-3 updates-notes-main-container ">
                                <ReleaseNotesComponent product={productId} defaultProduct={products[0]?._id} />
                            </div>
                        </div>
                        <div className="tab-pane fade show active" id="pills-updates" role="tabpanel" aria-labelledby="pills-updates-tab">
                            <div className="container mt-3 px-3 updates-notes-main-container">
                                <HistoryComponent product={productId} defaultProduct={products[0]?._id} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
      
    )
}

export default Updates;
