/**
 * File: Step2OnProduct.js
 * Description: Select the device to create tickets.
 * Author: Sulakshanee Theja
 * Created: 19/8/2023
 * Last Modified: 5/2/2024 Sulakshanee Theja
 */

import React, { useCallback } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Swal from 'sweetalert2';
import Select from 'react-select';

const Step2OnProduct = ({ step, setStep, device, setDevice, deviceOptions }) => {
  // To go to the previous step
  const previousStep = useCallback(() => {
    setStep(step - 1);
  }, [setStep, step]);

  // To go to the next step
  const nextStep = useCallback(() => {
    if (device) {
      setStep(step + 1);
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select a device to proceed.',
        confirmButtonColor: '#024b77',
      });
    }
  }, [device, setStep, step]);

  const handleDeviceChange = (selectedOption) => {
    setDevice(selectedOption);
  };

  return (
    <>
      <div className="step-title">Select your Device</div>

      <div className="description">
        You can select your device from the drop-down list to continue.
      </div>

      <div className="select-container">
        <Select
          className="basic-single"
          classNamePrefix="select"
          value={device && deviceOptions.filter((option) => option.value === device.value)[0]}
          defaultValue={''}
          isSearchable={true}
          name="device"
          options={deviceOptions}
          onChange={handleDeviceChange}
        />
      </div>

      <div className="onProduct-2-arrow-container">
        <Icon.ArrowLeft className="arrow-button" color="#0D2841" size={24} onClick={previousStep} />

        <Icon.ArrowRight className="arrow-button" color="#0D2841" size={24} onClick={nextStep} />
      </div>
    </>
  );
};

export default Step2OnProduct;
