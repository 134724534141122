import React from 'react';
// import SidePanel from '../../components/sidePanel';
// import * as Icon from 'react-bootstrap-icons';
import '../../styles/products.scss';
import SubscriberCount from '../../components/products/subscriberCount';
import BillingDetails from '../../components/products/billingDetails';
import { useParams } from 'react-router';

function Billing() {

    const params = useParams();
    // const [toggled, setToggled] = useState(false);

    // const handleToggleSidebar = (value) => {
    //     setToggled(value);
    // };

    return (
        <div className="container-fluid m-0 p-0" id="main-container">
            <div className="row p-0 mx-0" id="main-row">
                {/* <SidePanel toggled={toggled} handleToggleSidebar={handleToggleSidebar} activeStatus='list' /> */}
                <div className="col-md-10 p-0 m-0">
                    {/* <div
                        className="btn-toggle toggleIcon my-2 mx-3"
                        onClick={() => handleToggleSidebar(true)}
                    >
                        <Icon.List color="#0D2841" size={28} />
                    </div> */}
                    <div className="container mt-4 mx-0">
                        <div className="container m-0" id="billing-container">
                            <SubscriberCount />
                            <BillingDetails id={params.id} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Billing;
