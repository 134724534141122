/**
 * File: ContactDetails.js
 * Description: This file contains the implementation of CRUD operations on user contact details.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 1/4/2024 Sulakshanee Theja
 */

import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
import { getContacts, addContact, updateContact, deleteContact } from '../../../api';
import {
  StyledBodyCell,
  StyledBodyRow,
  StyledHeadCell,
} from '../../../components/table/StyledTable';

const ContactDetails = ({ getProfileDetails }) => {
  const [contactId, setContactId] = useState('');
  const [contactDetails, setContactDetails] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [inchargeOf, setInchargeOf] = useState();
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getContactDetails = async () => {
    const res = await getContacts();
    const userContactDetails = res.data.data;

    if (userContactDetails) {
      setContactDetails(userContactDetails);
    }
  };

  useEffect(() => {
    getContactDetails();
  }, []);

  const clearValues = () => {
    setContactId('');
    setName('');
    setEmail('');
    setMobile('');
    setInchargeOf('');
    setErrors('');
  };

  const validateContactDetails = () => {
    let errors = {};

    if (!name) {
      errors.name = 'Name required';
    }

    if (!email) {
      errors.email = 'Email required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      errors.email = 'Email address is invalid';
    }

    if (!mobile) {
      errors.mobile = 'Mobile number required';
    }

    if (!inchargeOf) {
      errors.inchargeOf = 'Incharge of required';
    }

    return errors;
  };

  const createNewContact = (e) => {
    e.preventDefault();
    const isValid = validateContactDetails();

    if (isValid.name || isValid.email || isValid.mobile || isValid.inchargeOf) {
      setErrors(isValid);
    } else {
      const body = {
        name: name,
        email: email,
        phone: mobile,
        incharge_of: inchargeOf,
      };

      addContact(body)
        .then((res) => {
          if (!res.data.error) {
            Swal.fire({
              icon: 'success',
              text: 'New Contact Added successfully !',
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
            clearValues();
            getContactDetails();
            handleModalClose();
          }
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            text: 'Failed to Add New Contact !',
            timer: 3000,
            confirmButtonColor: '#024b77',
          });
        });
    }
  };

  const updateContactDetails = (e) => {
    e.preventDefault();
    const isValid = validateContactDetails();

    if (isValid.name || isValid.email || isValid.mobile || isValid.inchargeOf) {
      setErrors(isValid);
    } else {
      const body = {
        id: contactId.toString(),
        name: name,
        email: email,
        phone: mobile,
        incharge_of: inchargeOf,
      };

      updateContact(body)
        .then((res) => {
          if (!res.data.error) {
            Swal.fire({
              icon: 'success',
              text: 'Contact Details Updated Successfully !',
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
            clearValues();
            getContactDetails();
            handleModalClose();
          }
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            text: 'Failed to Update New Contact !',
            timer: 3000,
            confirmButtonColor: '#024b77',
          });
        });
    }
  };

  const deleteSelectedContact = (contact_id) => {
    Swal.fire({
      icon: 'question',
      text: 'Are you sure you want to delete this Contact ?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Delete',
      denyButtonText: 'Cancel',
      confirmButtonColor: '#024b77',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          id: contact_id.toString(),
        };

        deleteContact(body)
          .then((res) => {
            if (!res.data.error) {
              getProfileDetails();
              getContactDetails();
              Swal.fire({
                icon: 'success',
                text: 'Contact Deleted Successfully !',
                timer: 3000,
                confirmButtonColor: '#024b77',
              });
            }
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              text: 'Failed to Delete Contact !',
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
          });
      }
    });
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="details-card-container">
        <div className="details-card-header">
          <div className="details-card-header-text">Contact Details</div>
          <div className="details-card-header-icon">
            <Icon.Plus
              color="#024B77"
              size={30}
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => {
                setContactId('');
                handleModalOpen();
              }}
            />
          </div>
        </div>

        {/* Contact details table */}
        <table className="table">
          <thead>
            <tr>
              <StyledHeadCell>Name</StyledHeadCell>
              <StyledHeadCell>Email</StyledHeadCell>
              <StyledHeadCell>Mobile</StyledHeadCell>
              <StyledHeadCell>Incharge Of</StyledHeadCell>
              <StyledHeadCell></StyledHeadCell>
              <StyledHeadCell></StyledHeadCell>
            </tr>
          </thead>

          <tbody>
            {contactDetails &&
              contactDetails.map((contactDetail) => (
                <StyledBodyRow key={contactDetail.id}>
                  <StyledBodyCell>{contactDetail.name}</StyledBodyCell>
                  <StyledBodyCell>{contactDetail.email}</StyledBodyCell>
                  <StyledBodyCell>{contactDetail.phone}</StyledBodyCell>
                  <StyledBodyCell>{contactDetail.incharge_of}</StyledBodyCell>
                  <StyledBodyCell>
                    <Icon.PencilSquare
                      color="#024B77"
                      className="details-table-icon"
                      size={20}
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      onClick={() => {
                        setContactId(contactDetail.id);
                        setName(contactDetail.name);
                        setEmail(contactDetail.email);
                        setMobile(contactDetail.phone);
                        setInchargeOf(contactDetail.incharge_of);
                        handleModalOpen();
                      }}
                    />
                  </StyledBodyCell>
                  <StyledBodyCell>
                    <Icon.Trash
                      color="#dc3545"
                      className="details-table-icon"
                      size={20}
                      onClick={() => {
                        deleteSelectedContact(contactDetail.id);
                      }}
                    />
                  </StyledBodyCell>
                </StyledBodyRow>
              ))}
          </tbody>
        </table>
      </div>

      <Modal show={isModalOpen} className="profile-modal-container">
        <Modal.Header className="profile-modal-header">
          {contactId ? (
            <h5 className="modal-title" id="staticBackdropLabel">
              Edit Contact
            </h5>
          ) : (
            <h5 className="modal-title" id="staticBackdropLabel">
              Create New Contact
            </h5>
          )}

          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              clearValues();
              handleModalClose();
            }}></button>
        </Modal.Header>

        <Modal.Body className="profile-modal-body">
          <div className="row profile-modal-field-container">
            <div className="col-md-4 profile-modal-label">Name</div>
            <div className="col-md-8">
              <input
                className="profile-modal-field"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrors({ ...errors, name: '' });
                }}
              />
              {errors.name && <p style={{ color: 'rgb(208, 0, 0)' }}>{errors.name}</p>}
            </div>
          </div>

          <div className="row profile-modal-field-container">
            <div className="col-md-4 profile-modal-label">Email</div>
            <div className="col-md-8">
              <input
                className="profile-modal-field"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors({ ...errors, email: '' });
                }}
              />
              {errors.email && <p style={{ color: 'rgb(208, 0, 0)' }}>{errors.email}</p>}
            </div>
          </div>

          <div className="row profile-modal-field-container">
            <div className="col-md-4 profile-modal-label">Phone</div>
            <div className="col-md-8">
              <input
                className="profile-modal-field"
                type="text"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                  setErrors({ ...errors, mobile: '' });
                }}
              />
              {errors.mobile && <p style={{ color: 'rgb(208, 0, 0)' }}>{errors.mobile}</p>}
            </div>
          </div>

          <div className="row profile-modal-field-container">
            <div className="col-md-4 profile-modal-label">Incharge of</div>
            <div className="col-md-8">
              <input
                className="profile-modal-field"
                type="text"
                value={inchargeOf}
                onChange={(e) => {
                  setInchargeOf(e.target.value);
                  setErrors({ ...errors, inchargeOf: '' });
                }}
              />
              {errors.inchargeOf && <p style={{ color: 'rgb(208, 0, 0)' }}>{errors.inchargeOf}</p>}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="profile-modal-footer">
          <button
            type="button"
            style={{ background: '#B3B9BF' }}
            data-bs-dismiss="modal"
            onClick={() => {
              clearValues();
              handleModalClose();
            }}>
            Close
          </button>

          {contactId ? (
            <button
              type="button"
              style={{ background: '#0472CC', color: '#FFFFFF' }}
              onClick={updateContactDetails}>
              Update
            </button>
          ) : (
            <button
              type="button"
              style={{ background: '#0472CC', color: '#FFFFFF' }}
              onClick={createNewContact}>
              Create
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactDetails;
