/**
 * File: App.js
 * Description: This is the main component that defines the routing and structure of the application.
 * Author: Nethmi Aththanayaka
 * Created: 22/8/2023
 * Last Modified: 16/8/2024 Sulakshanee Theja
 */

import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PrivateWrapper from './components/authWrappers/PrivateWrapper';
import PublicWrapper from './components/authWrappers/PublicWrapper';
import Layout from './components/layouts/Layout';
import Login from './routes/auth/Login';
import ForgotPassword from './routes/auth/ForgotPassword';
import CheckResetToken from './routes/auth/CheckResetToken';
import ResetPassword from './routes/auth/ResetPassword';
import Registration from './routes/auth/Registration';
import Dashboard from './routes/dashboard/Dashboard';
import UserProfile from './routes/userProfile/UserProfile';
import ProductOverview from './routes/products/ProductOverview';
import DeviceDetails from './routes/products/DeviceDetails';
import ProductBilling from './routes/products/billing';
import Tickets from './routes/ticket/Tickets';
import CreateTicket from './routes/ticket/CreateTicket';
import Updates from './routes/updates';
import Billing from './routes/billing/Billing';
import BillingHistory from './routes/billing/components/BillingHistory';
import PaymentConfirmation from './routes/payments';
import PasswordResetError from './routes/ErrorPages/PasswordResetError';
import './style.scss';
import './app.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PublicWrapper>
        <Login />
      </PublicWrapper>
    ),
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/resetPassword/:uuid',
    element: <CheckResetToken />,
  },
  {
    path: '/reset-Password',
    element: <ResetPassword />,
  },
  {
    path: '/registration',
    element: <Registration />,
  },
  {
    path: '/reset-password-error',
    element: <PasswordResetError />,
  },
  {
    path: '/payment_confirmation/:token',
    element: <PaymentConfirmation />,
  },
  {
    path: '/',
    element: (
      <PrivateWrapper>
        <Layout />
      </PrivateWrapper>
    ),
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/products',
        element: <ProductOverview />,
      },
      {
        path: '/:productName/:id',
        element: <DeviceDetails />,
      },
      {
        path: '/products/:id/billing',
        element: <ProductBilling />,
      },
      {
        path: '/billing',
        element: <Billing />,
      },
      {
        path: '/billing-history',
        element: <BillingHistory />,
      },
      {
        path: '/create-ticket',
        element: <CreateTicket />,
      },
      {
        path: '/ticket/:id',
        element: <Tickets />,
      },
      {
        path: '/ticket',
        element: <Tickets />,
      },
      {
        path: '/profile',
        element: <UserProfile />,
      },
      {
        path: '/updates',
        element: <Updates />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
