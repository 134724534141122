/**
 * File: UserProfile.js
 * Description: This file contains the implementation of displaying client details. Then includes CRUD operations on contact
 *              details. Also includes password change modal.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 30/4/2024 Sulakshanee Theja
 */

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import UserDetails from './components/UserDetails';
import SupportDetails from './components/SupportDetails';
import ContactDetails from './components/ContactDetails';
import '../../styles/userProfile.scss';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { getProfile, changePassword } from '../../api';
import { useAuth } from '../../contexts/AuthContext';

function UserProfile() {
  const navigate = useNavigate();
  const { setLocalStorageToken } = useAuth();

  const [client, setClient] = useState([]);

  // Change Password
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getProfileDetails = useCallback(() => {
    getProfile()
      .then((res) => {
        const clientProfile = res.data.data;

        if (!res.data.error) {
          setClient(clientProfile[0]);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    getProfileDetails();
  }, [getProfileDetails]);

  const clearValues = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setErrors('');
  };

  const validateChangePassword = () => {
    let errors = {};

    if (!currentPassword) {
      errors.currentPassword = 'Current password required';
    }

    if (!newPassword) {
      errors.newPassword = 'New password required';
    }

    if (!confirmPassword) {
      errors.confirmPassword = 'Confirm password required';
    }

    return errors;
  };

  const changeCurrentPassword = (e) => {
    e.preventDefault();
    const isValid = validateChangePassword();

    if (isValid.currentPassword || isValid.newPassword || isValid.confirmPassword) {
      setErrors(isValid);
    } else {
      if (newPassword === confirmPassword) {
        const body = {
          password: currentPassword,
          new_password: newPassword,
          verify_password: confirmPassword,
        };

        changePassword(body)
          .then((res) => {
            if (!res.data.error) {
              Swal.fire({
                icon: 'success',
                text: 'Password Changed Successfully !',
                timer: 3000,
                confirmButtonColor: '#024b77',
              });
              clearValues();
              handleModalClose();
              localStorage.clear();
              setLocalStorageToken(null);
              navigate('/');
            }
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              text: 'Failed to Change Password !',
              timer: 3000,
              confirmButtonColor: '#024b77',
            });
          });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Password mismatch. Check your Confirmation Password !',
          timer: 3000,
          confirmButtonColor: '#024b77',
        });
      }
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="m-0 container-fluid main-body-container">
        <div className="user-profile-head">
          <div
            className="change-password-text"
            data-bs-toggle="modal"
            data-bs-target="#changePassword"
            onClick={handleModalOpen}>
            Change Password
          </div>
        </div>

        <div className="user-profile-body">
          <UserDetails client={client} />

          <div className="row">
            <div className="col-md-8">
              <ContactDetails
                contactDetails={client.contactDetails}
                getProfileDetails={getProfileDetails}
              />
            </div>

            <div className="col-md-4">
              <SupportDetails
                company={client.company}
                contactPerson={client.contact_person}
                client={client}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} className="profile-modal-container">
        <Modal.Header className="profile-modal-header">
          <h5 className="modal-title" id="changePassword">
            Change Password
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              clearValues();
              handleModalClose();
            }}></button>
        </Modal.Header>

        <Modal.Body>
          <div className="row profile-modal-field-container">
            <div className="col-md-5 profile-modal-label">Current password</div>
            <div className="col-md-7">
              <input
                className="profile-modal-field"
                type="text"
                value={currentPassword}
                onChange={(e) => {
                  setCurrentPassword(e.target.value);
                  setErrors({ ...errors, currentPassword: '' });
                }}
              />
              {errors.currentPassword && (
                <p style={{ color: 'rgb(208, 0, 0)' }}>{errors.currentPassword}</p>
              )}
            </div>
          </div>

          <div className="row profile-modal-field-container">
            <div className="col-md-5 profile-modal-label">New password</div>
            <div className="col-md-7">
              <input
                className="profile-modal-field"
                type="email"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setErrors({ ...errors, newPassword: '' });
                }}
              />
              {errors.newPassword && (
                <p style={{ color: 'rgb(208, 0, 0)' }}>{errors.newPassword}</p>
              )}
            </div>
          </div>

          <div className="row profile-modal-field-container">
            <div className="col-md-5 profile-modal-label">Confirm password</div>
            <div className="col-md-7">
              <input
                className="profile-modal-field"
                type="text"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setErrors({ ...errors, confirmPassword: '' });
                }}
              />
              {errors.confirmPassword && (
                <p style={{ color: 'rgb(208, 0, 0)' }}>{errors.confirmPassword}</p>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="profile-modal-footer">
          <button
            type="button"
            style={{ background: '#B3B9BF' }}
            data-bs-dismiss="modal"
            onClick={() => {
              clearValues();
              handleModalClose();
            }}>
            Close
          </button>

          <button
            type="button"
            style={{ background: '#0472CC', color: '#FFFFFF' }}
            onClick={changeCurrentPassword}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserProfile;
