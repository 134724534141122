/**
 * File: notificationConstants.js
 * Description: Notification related constants
 * Author: Sulakshanee Theja
 * Created: 15/11/2023
 * Last Modified: 13/2/2024 Sulakshanee Theja
 */

// notification type
export const NOTIFICATION_TYPE = {
  ADDED_COMMENT: 1,
  UPDATED_TICKET: 2,
  UPDATED_TICKET_STATUS: 3,
  CREATED_TICKET: 4,
};

// notification status
export const NOTIFICATION_STATUS = {
  NOT_READ: 0,
  READ: 1,
};
